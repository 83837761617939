import { Box, useTheme, Button, FormControlLabel, Switch } from "@mui/material";

import { tokens } from "../theme";
import React, { useState, useEffect} from "react";
import ActionFeed from "../components/ActionFeed";
import AgentForm from "../components/AgentForm";

import Cookies from "js-cookie";

import { PlConfig } from "../types/PlConfig";
import { PlRun } from "../types/PlRun";
import { UserProfile } from "../types/UserProfile";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../auth/firebase";
import {
    apiUrl, 
    getCurrentOrigin, 
    fetch_with_auth,
} from "../components/Util";

import ReactFlow, {
    useNodesState,
    useEdgesState,
} from 'reactflow';

import toast from "react-hot-toast";
import { SavedItem } from "../types/SavedItem";
import { DefaultCopyField } from "@eisberg-labs/mui-copy-field";
import FuelSourceCreator from "../components/FuelSourceCreator";

const PipelinePage = () => {
    const auth = getAuth(app);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [plRun, setPlRun] = useState<PlRun>(new PlRun({
        pl_config_hash: "",
        run_id: "",
        state: "",
        created_ts: null,
        finished_ts: null,
        openai_token_hash: null,
        fuel_source_id: null,
        user_id: "",
        memory_objects_written: null,
        log: null,
    }));

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [operatorDeclarations, setOperatorDeclarations] = useState([]);

    const [plConfig, setPlConfig] = useState(new PlConfig({
        pl_config_hash: "",
        creator_user_id: "",
        created_ts: null,
        token_limit: null,
        budget_limit_usd: null,
        memory_objects: [],
        temperature: 1.0,
        preferred_model: "",
        pipeline: [],
    }));

    const [savedItem, setSavedItem] = useState(new SavedItem({
        saved_item_id: "",
        saved_item_type: "",
        saved_item_ref: "",
        name: null,
        description: null,
        is_public: true,
        star_count: 1,
        author_id: "",
        author_name: null,
        created_ts: null,
    }));


    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const [isRunning, setIsRunning] = useState(false);
    const [showOutputFiles, setShowOutputFiles] = useState(false);
    var decodedInputs = "";

    const fetchOperatorDeclarations = async (id_token: string) => {      
        try {
            const data = await fetch_with_auth(
                'operator_declarations', 
                id_token, 
                "GET", 
            );

            setOperatorDeclarations(data['pipeline_components']);
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                await fetchOperatorDeclarations(up.id_token || ""); 
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const newFuelSourceId = searchParams.get("fuel_source_id");
            //If there is a new fuel source id, reset the cookie FSID
            if (newFuelSourceId) {
                Cookies.set("__FSID", newFuelSourceId, { sameSite: "strict" });
                userProfile.update(setUserProfile, {
                    fuel_source_id: newFuelSourceId,
                    openai_fuel_preference: "fuel_source",
                });
            }

            await userProfile.init(setUserProfile, auth.currentUser);

            try {
                const runIdFromParams = searchParams.get("run_id");
                const savedPipelineId = searchParams.get("saved_pipeline_id");
                const inputs = searchParams.get("inputs");

                if (runIdFromParams) {
                    const { plRun: newPlRun, plConfig: newPlConfig } =
                        await PlRun.loadRunAndConfig(runIdFromParams);
                    if (newPlRun) {
                        setIsRunning(newPlRun.isRunning());
                        plRun.update(setPlRun, newPlRun);
                    }

                    if (newPlConfig) {
                        plConfig.update(setPlConfig, newPlConfig);
                    }
                }
                if (savedPipelineId) {
                    // Note that if both run_id and agent_id GET parameters are present we
                    // give preference to loading the run.
                    const { savedItem: newSavedItem, plConfig: newPlConfig } =
                        await SavedItem.loadSavedItemAndPlConfig(savedPipelineId);
                    if (newSavedItem) {
                        savedItem.update(setSavedItem, newSavedItem);
                    }
                    //Only overwrite PL config if there is no run_id
                    if(!runIdFromParams && newPlConfig){
                        // If inputs are present, edit the plConfig to include them. 
                        // Only do this if there is no run_id, we want to see original inputs for debugging run.
                        if (inputs && inputsAreValid(inputs)) {
                            const modifiedPlConfig = populateInputs(inputs, newPlConfig);
                            plConfig.update(setPlConfig, modifiedPlConfig);
                        }
                        else {
                            plConfig.update(setPlConfig, newPlConfig);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error(
                    `Failed to fetch pipeline configuration: ${String(error)}`
                );
            }
        };

        fetchData();
    }, []);

    const inputsAreValid = (inputs:string) => {
        // Check the inputs can be decoded and json parsed
        // We can add more input checks here if necessary. 
        try {
            decodedInputs = JSON.parse(decodeURIComponent(inputs));
            return true;
        } catch (error) {
            console.log("URL inputs are invalid: ", error);
            return false;
        }
    }

    const populateInputs = (inputs: string, plConfig: PlConfig) => {
        // Decoding the URL parameter and converting it to a JSON object
        const decodedInputs = JSON.parse(decodeURIComponent(inputs));
        // Iterating through the steps in the pipeline
        const updatedSteps = plConfig.pipeline.map(step => {
            if (step.operator === 'Input') {
                // Get the input_name from the step's parameters
                const inputName = step.parameters.input_name;
                // Check if there's a matching field in the decoded inputs
                if (decodedInputs.hasOwnProperty(inputName)) {
                    // If there's a match, update the value in the step's parameters
                    return {
                        ...step,
                        parameters: {
                            ...step.parameters,
                            value: decodedInputs[inputName]
                        }
                    };
                }
            }
            return step;
        });
    
        // Creating a new PlConfig instance with the updated steps
        const updatedPlConfig = new PlConfig({
            ...plConfig,
            pipeline: updatedSteps,
        });
    
        // Updating the plConfig state
        return updatedPlConfig;
    };


    return (
        <div className="flex flex-col min-h-[90vh] min-w-full space-y-6">
            
            <div className="flex flex-col min-h-[140vh] max-h-full md:flex-row">
                <div
                    id="left-half"
                    className="flex flex-col w-full md:w-6/6 mx-auto p-4 pt-0 md:pr-2"
                >
                    <AgentForm
                        plRun={plRun}
                        setPlRun={setPlRun}
                        plConfig={plConfig}
                        setPlConfig={setPlConfig}
                        savedItem={savedItem}
                        setSavedItem={setSavedItem}
                        userProfile={userProfile}
                        setUserProfile={setUserProfile}
                        isRunning={isRunning}
                        setIsRunning={setIsRunning}
                        
                        nodes={nodes}
                        setNodes={setNodes}
                        onNodesChange={onNodesChange}
                        edges={edges}
                        setEdges={setEdges}
                        onEdgesChange={onEdgesChange}
                        
                        operatorDeclarations={operatorDeclarations}
                    />
                </div>
                {plRun.log && (
                    <div
                        id="right-half"
                        className="flex flex-col space-y-4 p-4 pt-0 w-full min-h-[50vh] md:min-h-full md:w-3/5 md:pl-2"
                    >
                        <ActionFeed
                            userProfile={userProfile}
                            plRun={plRun}
                            isRunning={isRunning}
                        />

                        {plRun.run_id && (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <DefaultCopyField
                                        className="min-w-full"
                                        label="Share this run"
                                        value={`${getCurrentOrigin()}/pipeline?run_id=${plRun.run_id}`}
                                        style={{ flexGrow: 4, width: '80%', minWidth: '80%', marginRight: '1%' }}
                                        InputLabelProps={{sx: {'&.Mui-focused': {color: 'currentColor', },},}}
                                    />
                                    
                                    <FuelSourceCreator
                                        userProfile={userProfile}
                                        setUserProfile={setUserProfile}
                                    />
                                </div>
                                
                                {userProfile.fuel_source_id && (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <DefaultCopyField
                                            className="min-w-full"
                                            label="Share this run with Fuel Source so that other can run it without providing OpenAI token."
                                            value={`${getCurrentOrigin()}/pipeline?fuel_source_id=${userProfile.fuel_source_id}&run_id=${plRun.run_id}`}
                                            style={{ flexGrow: 4, width: '80% ', maxWidth: '80%', marginRight: '1%' }}
                                            InputLabelProps={{sx: {'&.Mui-focused': {color: 'currentColor', },},}}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PipelinePage;
