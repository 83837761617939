import React, { useCallback, Dispatch } from "react";
import { HighlightOff, HelpOutline } from "@mui/icons-material";
import { Handle, Node, Edge, Position } from "reactflow";
import Typography from "@mui/material/Typography";
import {
    Box,
    Button,
    Input,
    FormControl,
    Select,
    MenuItem,
    useTheme,
    Switch,
    FormControlLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from '@mui/material/Checkbox';
import { tokens } from "../theme";
import { OperatorDeclaration } from "../interfaces/OperatorDeclaration"; 
import OperatorTooltip from "./OperatorTooltip";
import { PipelineStepType, PlConfig } from "../types/PlConfig";


interface ParameterType {
    name: string;
    data_type: string;
    placeholder: string;
    value: string;
}

interface InputOutputType {
    name: string; 
    data_type: string;
}

interface OperatorNodeData {
    name: string;
    inputs: InputOutputType[];
    parameters: ParameterType[];
    outputs: InputOutputType[];
    declaration: OperatorDeclaration;
    batch: boolean;
    
    
    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;

    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;
    
    plConfig: PlConfig;
    setPlConfig: Dispatch<React.SetStateAction<PlConfig>>;
}

export interface OperatorNodeProps {
    data: OperatorNodeData;
    isConnectable: boolean;
    id: string;
}

const OperatorNode: React.FC<OperatorNodeProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const additionalParametersExist = props.data.declaration.additional_parameters.some(
        (additionalParam) => props.data.parameters.some((param) => param.name === additionalParam.name)
    );

    const [showAdditionalParameters, setShowAdditionalParameters] = React.useState(additionalParametersExist);
    
    const onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.data.setNodes((prevNodes) => {
            const newNodes = prevNodes.filter((n) => n.id !== props.id);
            props.data.setEdges((prevEdges) => {
                const newEdges = prevEdges.filter((e) => e.source !== props.id && e.target !== props.id);
                const newPipeline = nodesToPipeline(newNodes, newEdges);
                props.data.plConfig.update(props.data.setPlConfig, {pipeline: newPipeline});
                return newEdges;
            });
            return newNodes;
        });
    };


    const handleNodeDataChange = (nodeDataChange: Partial<OperatorNodeData>) => {       
        props.data.setEdges(edges => {
            // This outer call of setEdges should not be needed but without it for some reason
            // handleNodeDataChange is seeing stale value of edges (an empty list) before any manipulation 
            // is done with the diagram. To fix this weird bug we are wrapping the code inside of setEdges that would
            // ensure that most up-to-date edges are used and thus first call to handleNodeDataChange
            // would not delete all edges in the pipeline.
            props.data.setNodes(nodes => {
                const updatedNodes = nodes.map(node => 
                    node.id === props.id
                        ? { ...node, data: { ...node.data, ...nodeDataChange } }
                        : node
                );
                const newPipeline = nodesToPipeline(updatedNodes, edges);
                props.data.plConfig.update(props.data.setPlConfig, {pipeline: newPipeline});    
                return updatedNodes;
            });
            return edges;
        });
    };


    const handleParameterChange = (
        parameterName: string,
        newValue: string,
    ) => {
        let parameterExists = props.data.parameters.find((param) => param.name === parameterName);
        let newParams : ParameterType[];
        if (parameterExists) {
            newParams = props.data.parameters.map((param) =>
                param.name === parameterName
                    ? { ...param, value: newValue }
                    : param
            );
        } else {
            newParams = [
                ...props.data.parameters, 
                { 
                    name: parameterName, 
                    value: newValue,
                    data_type: '',  // It is ok to leave these empty because declarations are used as source of truth
                                    // for parameter data_type and placeholder.
                    placeholder: '',
                }
            ];
        }

        handleNodeDataChange({ parameters: newParams });
    };


    
    
    const renderParameters = () => {
        let paramsToRender = (
            showAdditionalParameters ?
            [...props.data.declaration.parameters, ...props.data.declaration.additional_parameters] :
            [...props.data.declaration.parameters]
        );

        return (
            paramsToRender.map((declarationParameter) => {
                const parameter = props.data.parameters.find((param) => param.name === declarationParameter.name);

                if (declarationParameter.condition && declarationParameter.condition.trim() !== "") {
                    const [conditionParamName, conditionValue] = declarationParameter.condition.split("==").map(str => str.trim());
                    const conditionParameter = props.data.parameters.find((param) => param.name === conditionParamName);

                    if (!conditionParameter || String(conditionParameter.value) !== conditionValue) {
                        // Don't render this parameter if the condition doesn't hold.
                        return null;  
                    }
                }

                let inputElement;

                if (declarationParameter.data_type === 'boolean') {
                    // if parameter type is boolean, render a switch
                    inputElement = (
                        <Checkbox
                            checked={parameter ? parameter.value === 'true' : false}
                            onChange={(event) => handleParameterChange(declarationParameter.name, String(event.target.checked))}
                        />
                    );
                } else if (declarationParameter.data_type.startsWith('enum(')) {
                    // if parameter type is enum, render a dropdown selector
                    // get enum values from string
         
                    const matchResult = declarationParameter.data_type.match(/\(([^)]+)\)/);
                    let enumValues = [];
                    if (matchResult !== null) {
                        enumValues = matchResult[1].split(',');
                    } else {
                        enumValues = ['!parameter definition error!'];
                    }

                    inputElement = (
                        <Select
                            className="nodrag"
                            name={`${declarationParameter.name}-selector`}
                            value={parameter ? parameter.value || "" : ""}
                            onChange={(e) => handleParameterChange(declarationParameter.name, e.target.value)}
                        >
                            {enumValues.map((value) => (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    );
                } else {
                    // otherwise, render regular input field
                    inputElement = (
                        <Input
                            defaultValue={parameter ? parameter.value || "" : ""}
                            onBlur={(e) => handleParameterChange(declarationParameter.name, e.target.value)}
                            className="min-w-full"
                            type={declarationParameter.data_type}
                            placeholder={declarationParameter.placeholder}
                            multiline
                        />
                    );
                }

                return (
                    <div key={declarationParameter.name}>
                        <label>{declarationParameter.name}: </label>
                        <Tooltip title={
                            <div style={{ fontSize: "16px" }}>
                                {declarationParameter.description}
                            </div>
                        } placement="top">
                            <HelpOutline />
                        </Tooltip>
                        {inputElement}
                    </div>
                );
            })
        );
    };
    
    
    return (
        <Box
            className="p-4 rounded-md space-y-3"
            style={{
                border: `1px solid ${colors.primary[300]}`,
                background: colors.primary[400],
                width: "500px"
            }}
        >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <b>{props.data.name}</b>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {props.data.declaration.allow_batch && (
                        <FormControlLabel
                            label="Batch Mode"
                            labelPlacement="start"
                            control={
                                <Checkbox
                                    checked={props.data.batch || false}
                                    onChange={(event) => handleNodeDataChange({batch: event.target.checked})}
                                />
                            }
                        />
                    )}
                    <button
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                        }}
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        <OperatorTooltip
                            declaration={props.data.declaration}
                        />
                    </button>
                    <button
                        onClick={onRemove}
                        style={{
                            marginLeft: 8,
                        }}
                    >
                        <HighlightOff />
                    </button>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                {props.data.inputs.map((input, i) => {
                    const commonProps = {
                        className: "source",
                        'data-handleid': input.name,
                        'data-handlepos': "top",
                        'data-nodeid': props.id,
                    };

                    return (
                        <Handle
                            type="target"
                            position={Position.Top}
                            style={{
                                backgroundColor: "#555",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                left: props.data.inputs.length > 1 
                                    ? `${(100.0 / (props.data.inputs.length - 1.0/3)) * (i + 1.0/3)}%`
                                    : '50%'
                            }}
                            id={input.name}
                            key={input.name}
                            isConnectable={props.isConnectable}
                        >
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -120%)'}}>
                                <Typography 
                                    variant="body1"
                                    {...commonProps}
                                >
                                    {input.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    {...commonProps}
                                >
                                    {`type=${props.data.batch ? input.data_type + '[]' : input.data_type}`}
                                </Typography>
                            </div>
                     
                        </Handle>
                    );
                })}
            </div>
            {renderParameters()}
            
            {props.data.declaration.additional_parameters && props.data.declaration.additional_parameters.length > 0 && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setShowAdditionalParameters((prevState) => !prevState);
                    }}
                >
                    {showAdditionalParameters ? "Hide additional parameters" : "Show additional parameters"}
                </Button>
            )}

            <div style={{display: "flex", justifyContent: "space-between"}}>
                {props.data.outputs.map((output, i) => {
                    const commonProps = {
                        className: "target",
                        'data-handleid': output.name,
                        'data-handlepos': "bottom",
                        'data-nodeid': props.id,
                    };

                    return (
                        <Handle
                            type="source"
                            position={Position.Bottom}
                            style={{
                                backgroundColor: "#555",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                left: props.data.outputs.length > 1 
                                    ? `${(100.0 / (props.data.outputs.length - 1.0/3)) * (i + 1.0/3)}%`
                                    : '50%'
                            }}
                            id={output.name}
                            key={output.name}
                            isConnectable={props.isConnectable}
                        >
                            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 10%)'}}>
                                <Typography 
                                    variant="body1"
                                    {...commonProps}
                                >
                                    {output.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    {...commonProps}
                                >
                                    {`type=${props.data.batch ? output.data_type + '[]' : output.data_type}`}
                                </Typography>
                            </div>
                     
                        </Handle>
                    );
                })}

            </div>
        </Box>
    );
};

function nodesToPipeline(nodes : Node[], edges : Edge[]): PipelineStepType[] {   
    const edgeMap = new Map<string, Edge[]>();

    for (const edge of edges) {
        const edgeArray = edgeMap.get(edge.target) || [];
        edgeArray.push(edge);
        edgeMap.set(edge.target, edgeArray);
    }

    const pipeline: PipelineStepType[] = nodes.map((node) => {
        // Example value of a node here:
        /*  {
                "id":"dndnode_1",
                "type":"operator",
                "position":{"x":-172.7086717860799,"y":-447.56448254136876},
                "data":{
                    "description":"",
                    "inputs":[],
                    "name":"Ingest PDF",
                    "outputs":[{"data_type":"Document[]","name":"pdf_content"}],
                    "parameters":[{"data_type":"string","name":"pdf_uri","placeholder":"Enter the URL of the PDF"}],
                    "secrets":[],
                    "batch": false
                },
                "width":270,
                "height":63,
                "selected":true,
                "dragging":false,
                "positionAbsolute":{"x":-172.7086717860799,"y":-447.56448254136876}
            }
        */
    
        const inputs: Record<string, [string, string]> = {};

        const nodeEdges = edgeMap.get(node.id) || [];
        for (const edge of nodeEdges) {
            if (edge.targetHandle) {
                inputs[edge.targetHandle] = [edge.source, edge.sourceHandle ?? ''];
            }
        }

        // Transform parameters to an object
        const parameters: Record<string, string> = {};
        for (const parameter of node.data.parameters) {
            parameters[parameter.name] = parameter.value || null;
        }

        return {
            id: node.id,
            operator: node.data.name,
            position: node.position,
            parameters,
            inputs: Object.keys(inputs).length > 0 ? inputs : {},
            batch: node.data.batch,
        };
    });


    return pipeline;
}

export default OperatorNode;

export {nodesToPipeline};
export type { ParameterType, InputOutputType, OperatorNodeData };
