import React, { Dispatch, SetStateAction } from "react";
import {
    Button,
    Box,
    TextField,
    useTheme,
} from "@mui/material";

import Popup from "reactjs-popup";

import {getAuth} from "firebase/auth";
import app from "../auth/firebase";
import { tokens } from "../theme";
import toast from "react-hot-toast";
import { PlConfig } from "../types/PlConfig";
import { UserProfile } from "../types/UserProfile";
import { SavedItem } from "../types/SavedItem";

interface SaveButtonProps {
    plConfig: PlConfig;
    userProfile: UserProfile;
    
    savedItem: SavedItem;
    setSavedItem: Dispatch<SetStateAction<SavedItem>>;

    isPublic: boolean;
    buttonLabel: string;
}

function SaveButton(props: SaveButtonProps) {
    const auth = getAuth(app);
    
    const [open, setOpen] = React.useState(false);

    const closeModal = () => setOpen(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handlePopupOpen = () => {
        if (!auth.currentUser) {
            toast.error(`Please sign in to {${props.buttonLabel}}`);
            return;
        }
        setOpen(true);
    };

    const handleSave = async () => {
        try {
            const plConfigHash = await props.plConfig.save_to_spanner();
            props.savedItem.saved_item_ref = plConfigHash;
            props.savedItem.author_id = props.userProfile.user_id;
            props.savedItem.author_name = props.userProfile.display_name || "Anonymous";
            props.savedItem.is_public = props.isPublic;
            props.savedItem.saved_item_type = 'pipeline';
            const { saved_item_id } = await props.savedItem.save_to_spanner(props.userProfile.id_token || '');
            
            toast.success(`${props.buttonLabel}: Successful.`);
            props.savedItem.update(props.setSavedItem, {saved_item_id: saved_item_id})
            
        } catch (error) {
            toast.error(`${props.buttonLabel}: Failed. ${error}`);
            console.error("Error:", error);
        }

        setOpen(false);
    };

    const contentStyle = {
        background: colors.primary[400],
        borderRadius: "20px",
        width: "300px",
        height: "450px",
        border: "1px solid",
        borderColor: colors.primary[200],
    };

    return (
        <div className="min-w-full">
            <Button
                onClick={handlePopupOpen}
                className="w-full"
                sx={{
                    backgroundColor: "transparent",
                    color: colors.primary[200],
                    border: "1px solid",
                    borderColor: colors.primary[200],
                    "&:hover": {
                        backgroundColor: `${colors.grey[800]} !important`,
                        cursor: "pointer",
                    },
                }}
            >
                {props.buttonLabel}
            </Button>

            <Popup
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
                contentStyle={contentStyle}
            >
                <Box className="w-full space-y-5" p={3}>
                    <h5>Describe your pipeline!</h5>
                    <TextField
                        label="Name"
                        value={props.savedItem.name}
                        onChange={(e) => props.savedItem.update(props.setSavedItem, {name: e.target.value})}
                        fullWidth
                    />
                    <TextField
                        label="Description"
                        value={props.savedItem.description}
                        onChange={(e) => props.savedItem.update(props.setSavedItem, {description: e.target.value})}
                        fullWidth
                        multiline
                        rows={6}
                    />
                    <div className="flex justify-end space-x-2">
                        <Button
                            onClick={closeModal}
                            sx={{
                                backgroundColor: `${colors.redAccent[700]} !important`,
                                color: "white",
                                "&:hover": {
                                    backgroundColor: `${colors.redAccent[800]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: `${colors.greenAccent[500]} !important`,
                                color: "white",
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[600]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                            onClick={handleSave}
                        >
                            {props.buttonLabel}
                        </Button>
                    </div>
                </Box>
            </Popup>
        </div>
    );
}

export default SaveButton;
