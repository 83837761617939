import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";

const ScreenSizeContext = createContext(false);

interface ScreenSizeProviderProps {
    children: ReactNode;
  }
  
export const useIsSmallScreen = () => {
  return useContext(ScreenSizeContext);
};

export const ScreenSizeProvider: React.FC<ScreenSizeProviderProps> = ({ children }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const smallScreen = window.innerWidth < 640;
      setIsSmallScreen(smallScreen);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <ScreenSizeContext.Provider value={isSmallScreen}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
