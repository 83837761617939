// CreateAgentPopup.tsx
import React, { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import Popup from "reactjs-popup";
import { tokens } from "../theme";

interface CreateItemPopupProps {
}

const CreateItemPopup: React.FC<CreateItemPopupProps> = ({}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const handleCreatePipeline = () => {
        window.location.href = `${window.location.origin}/pipeline`;
    };
    
    const handleCreateSpace = () => {
        window.location.href = `${window.location.origin}/space_editor`;
    }

    const contentStyle = {
        background: colors.primary[400],
        border: `1px solid ${colors.primary[300]}`,
        borderRadius: "20px",
    };

    return (
        <>
            <Button
                sx={{
                    backgroundColor: "transparent",
                    border: `1px solid`,
                    borderColor: colors.primary[300],
                    color: colors.primary[100],
                    "&:hover": {
                        backgroundColor: colors.greenAccent[800],
                        cursor: "pointer",
                        borderColor: colors.primary[100],
                    },
                }}
                onClick={() => setOpen((o) => !o)}
            >
                {"+ New pipeline"}
            </Button>
            <Popup
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
                {...{ contentStyle }}
            >
                <Box
                    className="w-full space-y-5"
                    sx={{
                        bgcolor: colors.primary[400],
                        p: 8,
                        borderRadius: "20px",
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        What do you want to create?
                    </Typography>
                    
                    <Box className="space-y-4" mb={2}>
                        <Button
                            sx={{
                                backgroundColor: `${colors.greenAccent[700]} !important`,
                                color: colors.primary[100],
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                            onClick={handleCreatePipeline}
                        >
                            + New Pipeline
                        </Button>

                        <Typography variant="body2">
                            A pipeline or a function that incapsulates certain logic.
                        </Typography>
                    </Box>
                    <br />
                    
                    
                    <Box className="space-y-4" mb={2}>
                        <Button
                            sx={{
                                backgroundColor: `${colors.greenAccent[700]} !important`,
                                color: colors.primary[100],
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                            onClick={handleCreateSpace}
                        >
                            + New Space
                        </Button>
                        <Typography variant="body2">
                            A workspace that defindes what objects can be stored in it, which functions AI can use to mutate 
                            objects and which people or teams have access to it.
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            backgroundColor: `${colors.redAccent[700]} !important`,
                            color: "white",
                            "&:hover": {
                                backgroundColor: `${colors.redAccent[800]} !important`,
                                cursor: "pointer",
                            },
                        }}
                        onClick={closeModal}
                    >
                        Close
                    </Button>
                </Box>
            </Popup>
        </>
    );
};

/*
Storing chatbot button in the comments in just case we want to bring it back soon.
TODO: delete this comment in August.
                    <Box className="space-y-4" mb={2}>
                        <Button
                            sx={{
                                backgroundColor: `${colors.grey[800]} !important`,
                                color: colors.primary[100],
                                "&:hover": {
                                    backgroundColor: `${colors.grey[700]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                        >
                            + New ChatBot (COMING SOON)
                        </Button>
                        <Typography variant="body2">
                            Instantly deploy and share a chatbot
                        </Typography>
                    </Box>
                    <br />
*/

export default CreateItemPopup;
