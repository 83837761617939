import React, { useState, useRef, useCallback, useEffect, DragEvent, Dispatch } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Controls,
  Node, 
  Edge, 
  Connection,
} from 'reactflow';
import shortUUID from 'short-uuid';
import OperatorNode, { 
    nodesToPipeline,  
} from './OperatorNode';
import { PipelineStepType, PlConfig } from "../types/PlConfig";
import { PlRun } from "../types/PlRun";

import 'reactflow/dist/style.css';

import PipelineDiagramOperatorSelector from './PipelineDiagramOperatorSelector';
import EdgeDataPopup from './EdgeDataPopup';
import { OperatorDeclaration } from "../interfaces/OperatorDeclaration"; 
import { useTheme } from '@mui/material';
import { tokens } from '../theme';

const nodeTypes = {
    operator: OperatorNode,
};


const getId = () => {
    const translator = shortUUID();
    return translator.new();
};

interface PipelineDiagramProps {
    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;
    onNodesChange: (newState: any[]) => void;
    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;
    onEdgesChange: (newState: any[]) => void;
    
    operatorDeclarations: OperatorDeclaration[];
    
    plConfig: PlConfig;
    setPlConfig: Dispatch<React.SetStateAction<PlConfig>>;

    plRun: PlRun;
}

const PipelineDiagram: React.FC<PipelineDiagramProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);

    const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);

    useEffect(() => {
        if (props.plConfig.pipeline) {        
            const { nodes, edges } = pipelineToNodes(props.plConfig.pipeline, props.operatorDeclarations);
            props.setNodes(nodes);
            props.setEdges(edges);
        }
    }, [props.plConfig.pipeline, props.operatorDeclarations]);

    const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
        setSelectedEdge(edge);
    }, []);

    const onPaneClick = useCallback(() => {
        setSelectedEdge(null);
    }, []);


    const onConnect = useCallback((connection: Connection) => {
        var edge_id = `e-${connection.source}-${connection.target}-${connection.targetHandle}`;

        const edge: Edge = {
            id: edge_id,
            source: connection.source ?? "",
            target: connection.target ?? "",
            sourceHandle: connection.sourceHandle ?? "",
            targetHandle: connection.targetHandle ?? "",
        };
        props.setEdges((eds) => {
            const newEdges = addEdge(edge, eds);
            const newPipeline = nodesToPipeline(props.nodes, newEdges);
            props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
            return newEdges;
        });
    }, [props.nodes, props.setEdges, props.edges, props.plConfig, props.setPlConfig]);

    const onEdgesDelete = useCallback((edgesToDelete: Edge[]) => {
        props.setEdges((currentEdges) => {
            const edgeIdsToDelete = edgesToDelete.map(edge => edge.id);
            const newEdges = currentEdges.filter(edge => !edgeIdsToDelete.includes(edge.id));
            const newPipeline = nodesToPipeline(props.nodes, newEdges);
            props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
            return newEdges;
        });
    }, [props.nodes, props.edges, props.setEdges, props.plConfig, props.setPlConfig]);
    
    const onDragOver = useCallback((event : DragEvent) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);
   

    function pipelineToNodes(
        pipeline: PipelineStepType[], 
        operatorDeclarations: OperatorDeclaration[],
    ): { nodes: Node[], edges: Edge[] } {
        
        function findPlaceholder(operatorDeclaration: any, paramName: string): string {
            for (let parameter of operatorDeclaration.parameters) {
                if (parameter.name === paramName) {
                    return parameter.placeholder || ""; // return an empty string if placeholder is null
                }
            }
            return "";
        }
        
        const nodes: Node[] = [];
        const edges: Edge[] = [];

        const nodeMap = new Map<string, Node>();

        pipeline.forEach((pipelineNode, index) => {
            // Example value of pipelineNode here:
            // {
            //     "id":"dndnode_3",
            //     "position": {"x":-172.7086717860799,"y":-447.56448254136876},
            //     "operator":"Web search",
            //     "parameters":{"query":"test","results_count":null}
            // }
            
            const operatorDeclaration = operatorDeclarations.find(op => op.name === pipelineNode.operator);
            // Example value of operatorDeclaration
            /*    {
                    "description": "",
                    "inputs": [],
                    "name": "Web search",
                    "outputs": [
                        {
                            "data_type": "{name,content}[]",
                            "name": "search_results"
                        }
                    ],
                    "parameters": [
                        {
                            "data_type": "string",
                            "name": "query",
                            "placeholder": "Enter your search query"
                        },
                        {
                            "data_type": "integer",
                            "name": "results_count",
                            "placeholder": "Enter the number of results"
                        }
                    ],
                    "additional_parameters": [
                        {
                            "data_type": "enum(gpt-4,gpt-3.5-turbo)",
                            "name": "model_preference",
                            "placeholder": ""
                        }
                    ],
                    "secrets": []
                }
            */

            if (!operatorDeclaration) {
                console.error(`Operator declaration not found for operator: ${pipelineNode.operator}`);
                return;
            }

            const node: Node = {
                id: pipelineNode.id,
                type: "operator",
                position: pipelineNode.position,
                data: {
                    name: pipelineNode.operator,
                    parameters: Object.entries(pipelineNode.parameters).map(([name, value]) => (
                        { name, placeholder: findPlaceholder(operatorDeclaration, name), data_type: "string", value }
                    )),
                    inputs: operatorDeclaration.inputs.map(({name, data_type}) => ({name, data_type})),
                    outputs: operatorDeclaration.outputs.map(({name, data_type}) => ({name, data_type})),
                    declaration: operatorDeclaration,
                    batch: pipelineNode.batch || false,
                    
                                        
                    edges: props.edges,
                    setEdges: props.setEdges,
                    nodes: props.nodes,
                    setNodes: props.setNodes,
                    plConfig: props.plConfig,
                    setPlConfig: props.setPlConfig,
                },
            };

            nodes.push(node);
            nodeMap.set(node.id, node);

            if (pipelineNode.inputs) {
                Object.entries(pipelineNode.inputs).forEach(([targetHandle, [source, sourceHandle]]) => {
                    var edge_id = `e-${source}-${pipelineNode.id}-${targetHandle}`;

                    const edge: Edge = {
                        id: edge_id,
                        source,
                        target: pipelineNode.id,
                        sourceHandle,
                        targetHandle,
                        animated: true,
                    };
                    edges.push(edge);
                });
            }
        });

        return { nodes, edges };
    }

    
    const onDrop = useCallback(
        (event: DragEvent) => {
            event.preventDefault();
            if (reactFlowInstance) {
                const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
                const type = event.dataTransfer.getData('application/reactflow');
                const nodeData = JSON.parse(event.dataTransfer.getData('application/reactflow-data'));

                const position = reactFlowInstance.project({
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top,
                });

                const operatorDeclaration = props.operatorDeclarations.find(op => op.name === nodeData.name);
                const newNode: Node = {
                    id: getId(),
                    type,
                    position,
                    data: {
                        ...nodeData,
                        declaration: operatorDeclaration,
                        
                        edges: props.edges,
                        setEdges: props.setEdges,
                        nodes: props.nodes,
                        setNodes: props.setNodes,
                        plConfig: props.plConfig,
                        setPlConfig: props.setPlConfig,
                    },
                };

                props.setNodes((nds: Node[]) => {
                    const updatedNodes = nds.concat(newNode);
                    const newPipeline = nodesToPipeline(updatedNodes, props.edges);
                    props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
                    return updatedNodes;
                });
            }
        },
        [reactFlowInstance, props.edges, props.setEdges, props.nodes, props.setNodes, props.plConfig, props.setPlConfig, props.operatorDeclarations]
    );


    const onNodeDragStop = useCallback(
        (event: any, node: Node) => {
            props.setNodes((ns) => {
                const updatedNodes = ns.map((n) => (n.id === node.id ? node : n));
                const newPipeline = nodesToPipeline(updatedNodes, props.edges);
                props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
                return updatedNodes;
            });
        },
        [props.setNodes, props.edges, props.setEdges, props.plConfig, props.setPlConfig]
    );

    const onEdgeUpdate = useCallback(
      (oldEdge: Edge, newConnection: Connection) => {
        const newEdge: Edge = {
          ...oldEdge,
          source: newConnection.source ?? "",
          target: newConnection.target ?? "",
          sourceHandle: newConnection.sourceHandle || null,
          targetHandle: newConnection.targetHandle || null,
        };

        props.setEdges((es) => {
          const updatedEdges = es.map((e) => (e.id === oldEdge.id ? newEdge : e));
          const newPipeline = nodesToPipeline(props.nodes, updatedEdges);
          props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
          return updatedEdges;
        });
      },
      [props.nodes, props.plConfig, props.setPlConfig]
    );

    const renderEdgeDataPopup = () => {
        console.log(`renderEdgeDataPopup: selectedEdge = ${selectedEdge}, props.plRun.step_outputs = ${props.plRun.step_outputs}`);
        if (selectedEdge && props.plRun.step_outputs) {
            return <EdgeDataPopup edge={selectedEdge} stepOutputs={props.plRun.step_outputs} />;
        }
        return null;
    };

    return (
        <div className="dndflow" 
        style={{ 
            border: `1px solid ${colors.primary[300]}`, 
            borderRadius: '5px',
            background: colors.primary[400]
        }}>
            <ReactFlowProvider>
                <div className="flex flex-row min-w-full">
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                  <ReactFlow
                    nodes={props.nodes}
                    edges={props.edges}
                    onNodesChange={props.onNodesChange}
                    onEdgesChange={props.onEdgesChange}
                    onConnect={onConnect}
                    onInit={setReactFlowInstance}
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                    fitView
                    nodeTypes={nodeTypes}
                    onNodeDragStop={onNodeDragStop}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgesDelete={onEdgesDelete}
                    onEdgeClick={onEdgeClick}
                    onPaneClick={onPaneClick}
                  >
                    <Controls />
                    {renderEdgeDataPopup()}
                  </ReactFlow>
                </div>
                <PipelineDiagramOperatorSelector 
                    ops={props.operatorDeclarations}
                />
                </div>
            </ReactFlowProvider>
        </div>
    );
};




export default PipelineDiagram;

