import React, {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
} from "react";
import { Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import {
    apiUrl,
    pipelineRequiresAuth,
    setCurrentUrlParam,
} from "./Util";
import AuthPopup from "./AuthPopup";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import FileSelector from "./FileSelector";
import ExtraSettings from "./ExtraSettings";
import { getAuth } from "firebase/auth";
import app from "../auth/firebase";

import { PlConfig } from "../types/PlConfig";
import { PlRun } from "../types/PlRun";
import { UserProfile } from "../types/UserProfile";
import ScheduleAgentButton from "./ScheduleAgentButton";
import PipelineDiagram from "./PipelineDiagram";
import { OperatorDeclaration } from "../interfaces/OperatorDeclaration"; 
import { nodesToPipeline } from "./OperatorNode";                         
import { SavedItem } from '../types/SavedItem';
import SaveButton from "./SaveButton";
                                    
                        
interface AgentFormProps {
    plRun: PlRun;
    setPlRun: Dispatch<SetStateAction<PlRun>>;

    plConfig: PlConfig;
    setPlConfig: Dispatch<SetStateAction<PlConfig>>;
    
    savedItem: SavedItem;
    setSavedItem: Dispatch<SetStateAction<SavedItem>>;

    userProfile: UserProfile;
    setUserProfile: Dispatch<SetStateAction<UserProfile>>;

    isRunning: boolean;
    setIsRunning: Dispatch<SetStateAction<boolean>>;
    
    nodes: any[];
    setNodes: Dispatch<React.SetStateAction<any[]>>;
    onNodesChange: (newState: any[]) => void;
    edges: any[];
    setEdges: Dispatch<React.SetStateAction<any[]>>;
    onEdgesChange: (newState: any[]) => void;
    
    operatorDeclarations: OperatorDeclaration[];
}

const AgentForm: React.FC<AgentFormProps> = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentUrl, setCurrentUrl] = useState("");
    const auth = getAuth(app);
    const [authRequired, setAuthRequired ] = useState(false);
    const [providers, setProviders] = useState<string[]>([]);

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null;

        if (props.isRunning) {
            let requestCount = 0;
            let intervalTime = 2000;

            const fetchAndUpdate = async () => {
                try {
                    const newPlRun = await PlRun.loadRun(props.plRun.run_id)
    
                    if (newPlRun) {
                        props.setIsRunning(newPlRun.isRunning());
                        //props.setPlRun({...props.plRun, ...newPlRun});
                        props.plRun.update(props.setPlRun, newPlRun);
                    }
                } catch (error) {
                    console.log(`fetchAndUpdate failed: ${error}`);
                }

                // Increment the request count and adjust the interval time if necessary
                requestCount++;
                if (requestCount % 3 === 0) {
                    intervalTime += 500;
                }

                // Schedule the next update
                interval = setTimeout(fetchAndUpdate, intervalTime);
            };

            // Start the updates
            fetchAndUpdate();
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [
        props.isRunning,
    ]);

    useEffect(() => {
        setCurrentUrl(window.location.href);
    }, [props.isRunning]);


    const deployAgent = async () => {
        try {
            const notification: string = props.isRunning
                ? toast.loading("Killing your pipeline run...")
                : toast.loading("Starting your pipeline run...");

            if (!props.isRunning) {
                // Not currently running thus new agent run need to be started.
                const newPlRun = await PlRun.startRun(
                    props.plConfig, 
                    props.userProfile, 
                    props.setUserProfile, 
                    props.userProfile.id_token || ""
                );
                
                // Not using update method because we create new run so it should not retain
                // any fields whatsoever from previous run.
                if (newPlRun){
                    props.setPlRun(newPlRun);
                    
                    setCurrentUrlParam('run_id', newPlRun.run_id);

                    toast.dismiss();
                    toast.success(`Pipeline run started!`);
                    
                    props.setIsRunning(true);
                } else {
                    toast.error("Failed to start pipeline run!");
                }
            } else {
                // This is attempt to terminate active run.
                const response = await fetch(`${apiUrl}/kill_agent`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ 'user_id' : props.userProfile.user_id, 'run_id' : props.plRun.run_id })
                });

                if (response.ok) {
                    toast.dismiss();
                    toast.success("Pipeline run queued for termination.");
                } else {
                    const responseData = await response.json();
                    toast.error(`Error: ${responseData.error}`);
                }
                
                // Note that we are not seeting isRunning state to false here because we want the call to get_run
                // to learn about termination of the run and show all relevant log to the user.
            } 
        } catch (error) {
            console.log(error);
            toast.dismiss();
            toast.error(String(error));
        }
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
  
        const newPipeline = nodesToPipeline(props.nodes, props.edges);
        props.plConfig.pipeline = newPipeline;
        props.plConfig.update(props.setPlConfig, {pipeline: newPipeline});
        
        const requiredProviders = pipelineRequiresAuth(props.plConfig.pipeline, auth.currentUser?.providerData);

        // If this pipeline requires any sort of account linking (twitter, github, etc.) Force the user to sign in. 
        if (requiredProviders.length > 0) {
            if (!auth.currentUser) {
                toast.error("This pipeline requires you to sign in!");
                return;
            }

            setProviders(requiredProviders);
            setAuthRequired(true);
            return;
        }

        if (props.userProfile.useOpenaiToken() && props.userProfile.openai_token) {
            Cookies.set("__OAI_TOK", props.userProfile.openai_token || "", {
                sameSite: "strict",
                expires: 7,
            }); 
        }
   
        if (props.userProfile.useOpenaiToken() && !props.userProfile.openai_token) {
            toast.error("Please enter your API key!");
            return;
        }
 
        deployAgent();
    };

    return (
        <>
            <form onSubmit={onSubmit} className="min-h-full space-y-4">             
                <AuthPopup authProviders={providers} authRequired={authRequired} />
                <PipelineDiagram
                    nodes={props.nodes}
                    setNodes={props.setNodes}
                    onNodesChange={props.onNodesChange}
                    
                    edges={props.edges}
                    setEdges={props.setEdges}
                    onEdgesChange={props.onEdgesChange}
                    
                    operatorDeclarations={props.operatorDeclarations}
                    
                    plConfig={props.plConfig}
                    setPlConfig={props.setPlConfig}

                    plRun={props.plRun}
                />
                

                <FileSelector 
                    userProfile={props.userProfile}
                    onSelectedFilesChange={(newSelectedFiles) => {
                        const nonFileMemoryObjects = props.plConfig.memory_objects?.filter(obj => !obj.startsWith('file:')) || [];
                        const fileMemoryObjects = newSelectedFiles.map(file => 'file:' + file);
                        const updatedMemoryObjects = [...nonFileMemoryObjects, ...fileMemoryObjects];
                        props.plConfig.update(props.setPlConfig, {memory_objects: updatedMemoryObjects});
                    }} 
                />

                <ExtraSettings 
                    plConfig={props.plConfig}
                    setPlConfig={props.setPlConfig}
                />
                <Button
                    className="w-full"
                    variant="contained"
                    type="submit"
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: props.isRunning
                            ? `${colors.redAccent[500]} !important`
                            : `${colors.greenAccent[500]} !important`,
                        background: props.isRunning
                            ? `${colors.redAccent[900]} !important`
                            : `${colors.greenAccent[900]} !important`,
                        "&:hover": {
                            backgroundColor: props.isRunning
                                ? `${colors.redAccent[800]} !important`
                                : `${colors.greenAccent[800]} !important`,
                        },
                    }}
                >
                    {props.isRunning
                        ? "Kill Running Pipeline"
                        : "Run Pipeline"}
                </Button>
                <div className="flex flex-row space-x-3">
                    <div className="w-1/3">
                        <SaveButton 
                            plConfig={props.plConfig}
                            userProfile={props.userProfile}
                            savedItem={props.savedItem}
                            setSavedItem={props.setSavedItem}
                            isPublic={false}
                            buttonLabel='Save'
                        />
                    </div>
                    <div className="w-1/3">
                        <SaveButton 
                            plConfig={props.plConfig}
                            userProfile={props.userProfile}
                            savedItem={props.savedItem}
                            setSavedItem={props.setSavedItem}
                            isPublic={true}
                            buttonLabel='Publish'
                        />
                    </div>
                    <div className="w-1/3">
                        {props.savedItem && 
                            <ScheduleAgentButton 
                                userProfile={props.userProfile}
                                plConfig={props.plConfig}
                                setUserProfile={props.setUserProfile}
                                savedItem={props.savedItem}
                                authRequired={authRequired}
                                setAuthRequired={setAuthRequired}
                                providers={providers}
                                setProviders={setProviders}
                            />}
                    </div>
                </div>

            </form>
        </>
    );
};

export default AgentForm;
