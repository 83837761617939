import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { TextField, Button, IconButton, Grid, Checkbox, FormControlLabel, Paper, Divider, Typography, useTheme } from '@mui/material';
import { tokens } from "../theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Autocomplete from '@mui/material/Autocomplete';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../auth/firebase";
import {
    apiUrl, 
    getCurrentOrigin, 
    fetch_with_auth,
    validateLettersNumbersOnly,
    setCurrentUrlParam,
} from "../components/Util";
import { SavedItem } from '../types/SavedItem';
import { UserProfile } from "../types/UserProfile";


const AgentEditorPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const [savedItem, setSavedItem] = useState<SavedItem>(new SavedItem({
        name: '',
        description: '',
        is_public: false,
        author_id: '',
        eligible_user_emails: [],
        eligible_teams: [],
        actions: {}
    }));
    const [publicPipelines, setPublicPipelines] = useState<SavedItem[]>([]);
    const [privatePipelines, setPrivatePipelines] = useState<SavedItem[]>([]);
    
    const handleSave = async () => {
        toast.loading("Saving Agent...")
        try {
            savedItem.author_id = userProfile.user_id;
            savedItem.author_name = userProfile.display_name || "Anonymous"; 
            savedItem.saved_item_type = 'agent';

            const res = await savedItem.save_to_spanner(userProfile.id_token || '');
            console.log(res);
            const { saved_item_id } = res;
            console.log(`saved_item_id = ${saved_item_id}`);
            savedItem.update(setSavedItem, {...savedItem, saved_item_id: saved_item_id });
            toast.dismiss()
            toast.success("Saved Agent successfully.");
            setCurrentUrlParam('agent_id', saved_item_id);
        } catch (error: any) {
            toast.dismiss()
            toast.error(`Failed to save item: ${error.message}`);
        }
    };
    
    const fetchSavedItemIfNeeded = async (id_token: string) => {      
        const searchParams = new URLSearchParams(window.location.search);
        const agentId = searchParams.get("saved_item_id") || searchParams.get("agent_id");
        if (!agentId) {
            return;
        }
        
        try {
            const data = await fetch_with_auth(
                `saved_item?saved_item_id=${agentId}`, 
                id_token, 
                "GET", 
            );

            setSavedItem(new SavedItem(data));
        } catch (error: any) {
            console.error(error);
            toast.error(`Error: ${error.message}`);
        }
    };    
    
    const fetchSavedPipelines = async (visibility : string, id_token : string) => {
        const data = await fetch_with_auth(
            `saved_item?saved_item_type=pipeline&visibility=${visibility}`, 
            id_token, 
            "GET"
        );
        return data;
    };

    useEffect(() => {
        fetchSavedPipelines('public', '').then(data => setPublicPipelines(data));
    }, []);


    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                await fetchSavedItemIfNeeded(up.id_token || ""); 
                fetchSavedPipelines('private', up.id_token || "").then(data => setPrivatePipelines(data));
            }
        });

        return () => unsubscribe();
    }, []);


    const handleAddAction = () => {
        const newSavedItem = new SavedItem({
            ...savedItem, 
            actions: {...savedItem.actions, '': ''} 
        });
        setSavedItem(newSavedItem);
    };

    const handleRemoveAction = (actionName : string) => {
        const { [actionName]: value, ...remainingActions } = savedItem.actions;
        const newSavedItem = new SavedItem({
            ...savedItem, 
            actions: remainingActions
        });
        setSavedItem(newSavedItem);
    };


    const handleActionNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, oldActionName: string) => {
        const newActionName = e.target.value;
        const newActions = savedItem.actions;
        newActions[newActionName] = newActions[oldActionName];
        delete newActions[oldActionName];

        setSavedItem(new SavedItem({
            ...savedItem, 
            actions: newActions,
        }));
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="name"
                    label="Name"
                    value={savedItem.name || ''}
                    onChange={(e) => setSavedItem(new SavedItem({
                        ...savedItem, 
                        name: e.target.value
                    }))}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    name="description"
                    label="Description"
                    value={savedItem.description || ''}
                    onChange={(e) => setSavedItem(
                        new SavedItem({...savedItem, description: e.target.value})
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="h6">Visibility</Typography>
                <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox 
                        checked={savedItem.is_public || false}
                        onChange={(e) => setSavedItem(
                            new SavedItem({...savedItem, is_public: e.target.checked})
                        )}
                    />}
                    label="Public"
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="emails"
                    label="Eligible User Emails"
                    value={savedItem.eligible_user_emails.join(", ")}
                    onChange={(e) => setSavedItem(
                        new SavedItem({...savedItem, eligible_user_emails: e.target.value.split(", ")})
                    )}
                    disabled={savedItem.is_public || false}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="teams"
                    label="Eligible Team Names"
                    value={savedItem.eligible_teams.join(", ")}
                    onChange={(e) => setSavedItem(
                        new SavedItem({...savedItem, eligible_teams: e.target.value.split(", ")})
                    )}
                    disabled={savedItem.is_public || false}
                />
            </Grid>
        
            {Object.entries(savedItem.actions).map(([actionName, pipelineId], index) => (
                <Grid item xs={12} key={index}>
                    <Paper elevation={3}>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    name="name"
                                    label="Action Name"
                                    value={actionName}
                                    onChange={(e) => handleActionNameChange(e, actionName)}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Autocomplete
                                    options={privatePipelines.concat(publicPipelines)}
                                    getOptionLabel={(option: SavedItem) => option ? (option.name || '') : ''}
                                    renderInput={(params) => <TextField {...params} label="Choose saved pipeline" />}
                                    value={publicPipelines.concat(privatePipelines).find(pipeline => pipeline.saved_item_id === pipelineId) || null}
                                    onChange={(event, newValue) => {
                                        setSavedItem(
                                            new SavedItem(
                                                {
                                                    ...savedItem, 
                                                    actions: {...savedItem.actions, [actionName]: newValue ? newValue.saved_item_id : ''} 
                                                }
                                            )
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} container justifyContent="center" alignItems="center">
                                <IconButton color="primary" onClick={() => handleRemoveAction(actionName)}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" />
                    </Paper>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button color="primary" onClick={handleAddAction}>
                    <AddCircleOutlineIcon /> Add Agent Function
                </Button>
            </Grid> 
            <Grid item xs={12}>
                <Button
                    className="w-full"
                    variant="contained"
                    type="submit"
                    onClick={handleSave}
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: `${colors.greenAccent[500]} !important`,
                        background: `${colors.greenAccent[900]} !important`,
                        "&:hover": `${colors.greenAccent[800]} !important`,
                    }}
                >
                    Save Agent
                </Button>
            </Grid>
        </Grid>
    );
};

export default AgentEditorPage;

