import React from 'react';
import {animated} from 'react-spring'; // adjust this import as per your directory structure

interface FeatureBoxProps {
    IconComponent: any; // for Icon component
    color: string; // for color
    title: string; // for Title
    description: string; // for Description
}

const FeatureBox: React.FC<FeatureBoxProps> = ({IconComponent, color, title, description}) => {
    return (
        <div className="w-full p-6">
            <animated.div className="w-full text-left space-y-4">
                <div className="flex flex-row space-x-4">
                    <IconComponent style={{color: color}}/>
                    <h2 className="font-semibold text-2xl">
                        {title}
                    </h2>
                </div>
                <p>
                    {description}
                </p>
            </animated.div>
        </div>
    );
};

export default FeatureBox;
