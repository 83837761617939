// ActionFeed.tsx
import React, { useRef, useEffect } from "react";
import Action from "./Action";
import { tokens } from "../theme";
import { Box, useTheme } from "@mui/material";
import { RaceBy } from "@uiball/loaders";
import FileList from "./FileList";
import { PlRun } from "../types/PlRun";
import { UserProfile } from "../types/UserProfile";

interface AgentLogProps {
    userProfile: UserProfile;
    plRun: PlRun;
    isRunning?: boolean;
}

const ActionFeed: React.FC<AgentLogProps> = ({
    userProfile,
    plRun,
    isRunning,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const actionsEndRef = useRef<HTMLDivElement>(null);
    const isFirstRender = useRef(true);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (scrollContainerRef.current && actionsEndRef.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight;
        }
    };
    
    function getFileObjects(memoryObjects: string[]) {
        return memoryObjects.filter(obj => obj.startsWith('file:')).map(obj => obj.slice(5));
    }

    function hasFileObjects(memoryObjects: string[]) {
        return getFileObjects(memoryObjects).length > 0;
    }

    useEffect(() => {
        if (!isFirstRender.current) {
            scrollToBottom();
        } else {
            isFirstRender.current = false;
        }
    }, [plRun]);

    return (
        <Box
            className="min-h-full md:max-h-[5vh] min-w-full"
            sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 1,
                border: "1px solid",
                color: colors.grey[200],
                backgroundColor: colors.primary[400],
                borderColor: colors.grey[500],
                padding: "12px 16px",
                textAlign: "top",
                maxHeight: "300px", // Set maxHeight here
                overflowY: "auto", // Set overflowY here
            }}
            ref={scrollContainerRef}
        >
            <div style={{ flexGrow: 1 }}>
                <div className="flex flex-col max-h-full w-full">
                    {(plRun.log || []).map((action, index) => (
                        <Action key={index} content={action} />
                    ))}
                    <div ref={actionsEndRef}></div>
                </div>
            </div>
            {isRunning && (
                <div
                    className="p-3"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <RaceBy
                        size={80}
                        lineWeight={5}
                        speed={1.4}
                        color={colors.greenAccent[500]}
                    />
                </div>
            )}

            {!isRunning && plRun.memory_objects_written && hasFileObjects(plRun.memory_objects_written) && (
                <div className="pt-5 pb-5">
                    <h2 className="pb-2 text-lg"> Generated files</h2>
                    <FileList
                        userProfile={userProfile}
                        files={getFileObjects(plRun.memory_objects_written)}
                        onDragStart={(e, fileName) => {
                            // Implement the drag start functionality here if needed
                        }}
                    />
                </div>
            )}
        </Box>
    );
};

export default ActionFeed;
