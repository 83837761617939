import React, { useState, Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { apiUrl } from "./Util";
import { getAuth } from "firebase/auth";
import app from "../auth/firebase";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import Cookies from "js-cookie";
import { UserProfile } from "../types/UserProfile";


interface FuelSourceCreatorProps {
    userProfile: UserProfile;
    setUserProfile: Dispatch<SetStateAction<UserProfile>>;
}

function FuelSourceCreator(props: FuelSourceCreatorProps) {
    const auth = getAuth(app);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //Fuel Source Form Fields
    const [fuelFormOpen, setFuelFormOpen] = useState(false);
    const [fuelApiKey, setFuelApiKey] = useState("");
    const [fuelName, setFuelName] = useState("");
    const [fuelMaxRunCount, setFuelMaxRunCount] = useState(100);
    const [fuelMaxStepsPerRun, setFuelMaxStepsPerRun] = useState(10);
    const [fuelMaxRunsPerUser, setFuelMaxRunsPerUser] = useState<
        number | undefined
    >(undefined);
    const [fuelTtl, setFuelTtl] = useState<number | undefined>(undefined);

    const handleCreateFuelSource = async () => {
        try {
            toast.loading("Creating new Fuel Source");
            const response = await fetch(`${apiUrl}/create_fuel_source`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    creator_uid: props.userProfile.user_id,
                    name: fuelName,
                    openai_token: fuelApiKey,
                    ttl_days: fuelTtl,
                    max_run_count: fuelMaxRunCount,
                    max_runs_per_user: fuelMaxRunsPerUser,
                    max_steps_per_run: fuelMaxStepsPerRun,
                }),
            });

            if (!response.ok) {
                throw new Error(
                    `Error ${response.status}: ${response.statusText}`
                );
            }

            const data = await response.json();

            if (data.success) {
                toast.dismiss();   
                props.userProfile.update(props.setUserProfile, {fuel_source_id: data.fuel_source_id})
                Cookies.set("__FSID", data.fuel_source_id, {
                    sameSite: "strict",
                });
                toast.success(
                    `Successfully created Fuel Source ${data.fuel_source_id}`
                );
            } else {
                toast.dismiss();
                toast.error("Failed to create Fuel Source");
                console.error("Failed to create Fuel Source");
            }
        } catch (error) {
            toast.dismiss();
            toast.error("Failed to create Fuel Source");
            console.error("Error:", error);
        }

        setFuelFormOpen(false);
    };

    const handleClickFuelFormOpen = () => {
        setFuelFormOpen(true);
        setFuelApiKey(props.userProfile.openai_token || "");
    };

    const handleClickFuelFormClose = () => {
        setFuelFormOpen(false);
    };

    return (
        <div>
            <Button
                onClick={handleClickFuelFormOpen}
                sx={{
                    backgroundColor: "transparent",
                    border: `1px solid`,
                    borderColor: colors.primary[300],
                    color: colors.primary[100],
                    "&:hover": {
                        backgroundColor: colors.greenAccent[800],
                        cursor: "pointer",
                        borderColor: colors.primary[100],
                    },
                }}
            >
                + New Fuel Source
            </Button>

            <Dialog open={fuelFormOpen} onClose={handleClickFuelFormClose}>
                <DialogTitle sx={{ fontSize: "1.2rem" }}>
                    Let people use your OpenAI token without making it public.
                </DialogTitle>
                <DialogContent>
                    <form>
                        <TextField
                            value={fuelApiKey}
                            onChange={(e) => setFuelApiKey(e.target.value)}
                            label="OpenAI API token"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                        <TextField
                            value={fuelName}
                            onChange={(e) => setFuelName(e.target.value)}
                            label="Name of Fuel Source (optional)"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                        <TextField
                            value={fuelMaxRunCount}
                            onChange={(e) =>
                                setFuelMaxRunCount(Number(e.target.value))
                            }
                            label="Number of times Fuel Source can be used to run AutoGPT"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                        <TextField
                            value={fuelMaxStepsPerRun}
                            onChange={(e) =>
                                setFuelMaxStepsPerRun(Number(e.target.value))
                            }
                            label="Limit number of steps of each AutoGPT run to avoid waste of resouces"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                        <TextField
                            value={fuelMaxRunsPerUser}
                            onChange={(e) =>
                                setFuelMaxRunsPerUser(Number(e.target.value))
                            }
                            label="Max number of times one person can use it (optional)"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                        <TextField
                            value={fuelTtl}
                            onChange={(e) => setFuelTtl(Number(e.target.value))}
                            label="Time to live in days, will expire after (optional)"
                            fullWidth
                            style={{ margin: "8px 0" }}
                            InputLabelProps={{
                                sx: {
                                    "&.Mui-focused": { color: "currentColor" },
                                },
                            }}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            backgroundColor: "#4CAF50", // Change this to your desired background color
                            color: "white", // Change this to your desired text color
                        }}
                        onClick={handleClickFuelFormClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2196F3", // Change this to your desired background color
                            color: "white", // Change this to your desired text color
                        }}
                        onClick={handleCreateFuelSource}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FuelSourceCreator;
