import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { format } from 'date-fns';
import {
    apiUrl,
} from "../components/Util";
import Header from '../components/Header';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../auth/firebase";
import FuelSourceCreator from '../components/FuelSourceCreator';
import { UserProfile } from '../types/UserProfile';


const FuelSourcesPage = () => {
    const [fuelSources, setFuelSources] = useState([]);
    const navigate = useNavigate();
    //const location = useLocation();
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));

    const fetchFuelSources = async (user_id : string) => {
        const response = await fetch(`${apiUrl}/fuel_sources?user_id=${user_id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        const sortedFuelSources = data.fuel_sources.sort(
            (a: any, b: any) => new Date(b.created_ts).getTime() - new Date(a.created_ts).getTime()
        );
        setFuelSources(sortedFuelSources);
    };

    useEffect(() => {
        const auth = getAuth(app);

        const refresh = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                fetchFuelSources(up.user_id!);
            }
        });

        return () => {
            refresh();
        };
    }, []);


    const handleClick = (fuel_source_id: string) => {
        navigate(`/?fuel_source_id=${fuel_source_id}`);
    };

    const renderDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, 'Pp');
    };

    const isExpired = (created_ts: string, ttl_days: number) => {
        const now = new Date();
        const expirationDate = new Date(created_ts).getTime() + ttl_days * 24 * 60 * 60 * 1000;
        return now.getTime() > expirationDate;
    };

    return (
        <div className="min-h-screen min-w-full p-6 space-y-3">
            <Header title="Your Fuel Sources" subtitle='A fuel source enables sharing of your API Key with no risk!'></Header>
            <div>
                <FuelSourceCreator 
                    userProfile={userProfile}
                    setUserProfile={setUserProfile}
                />
            </div>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Fuel Source Id</TableCell>
                            <TableCell>OpenAI Token</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Expires On</TableCell>
                            <TableCell>Exhausted</TableCell>
                            <TableCell>Runs Completed</TableCell>
                            <TableCell>Max Runs/User</TableCell>
                            <TableCell>Max Steps/Run</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fuelSources.map((fuelSource: any) => (
                            <TableRow key={fuelSource.fuel_source_id}>
                                <TableCell component="th" scope="row">
                                    {fuelSource.name}
                                </TableCell>
                                <TableCell onClick={() => handleClick(fuelSource.fuel_source_id)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                    {fuelSource.fuel_source_id}
                                </TableCell>
                                <TableCell>***{fuelSource.openai_token.slice(-6)}</TableCell>
                                <TableCell>{renderDate(fuelSource.created_ts)}</TableCell>
                                <TableCell style={isExpired(fuelSource.created_ts, fuelSource.ttl_days) ? { color: 'red' } : {}}>
                                    {fuelSource.ttl_days ? renderDate(new Date(new Date(fuelSource.created_ts).getTime() + fuelSource.ttl_days * 24 * 60 * 60 * 1000).toISOString()) : 'N/A'}
                                </TableCell>
                                <TableCell style={fuelSource.exhausted_ts ? { color: 'red' } : {}}>
                                    {fuelSource.exhausted_ts ? renderDate(fuelSource.exhausted_ts) : 'N/A'}
                                </TableCell>
                                <TableCell>
                                    {fuelSource.run_count} / {fuelSource.max_run_count}
                                </TableCell>
                                <TableCell>{fuelSource.max_runs_per_user}</TableCell>
                                <TableCell>{fuelSource.max_steps_per_run}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FuelSourcesPage;

