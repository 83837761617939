import React from "react";
import { Box, Grid } from "@mui/material";
import AgentCard from "./AgentCard";
import { SavedItem } from "../types/SavedItem";
import { UserProfile } from "../types/UserProfile";


interface PublishedAgentGridProps {
    items: SavedItem[];
    userProfile: UserProfile;
}

const PublishedAgentsGrid: React.FC<PublishedAgentGridProps> = ({ items, userProfile }) => {
    const sortedAgents = [...items].sort((a, b) => b.star_count - a.star_count);

    return (
        <Box className="min-w-full">
            <Grid container spacing={2} justifyContent="flex-start">
                {sortedAgents.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                        <AgentCard item={item} userProfile={userProfile}/>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PublishedAgentsGrid;
