// Footer.tsx
import React from "react";

const Footer: React.FC = () => {
    const discordUrl = "https://discord.gg/CrZuvzW7";

    return (
        <div className="p-4">
            <div className="container mx-auto">
                <div className="flex flex-col justify-center items-center space-x-4">
                    <div>
                        {/* Real time support Discord {" "}
                        <a
                            href={discordUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#1DA1F2', textDecoration: 'underline' }}
                        >
                            DISCORD
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
