import React, { useState } from "react";
import { Ring } from "@uiball/loaders";

interface LoaderProps {
    size: number;
  }

function Loader({ size }: LoaderProps) {
    return (
        <div>
            <Ring size={size} lineWeight={5} speed={2} color="black" />
        </div>
    );
}

export default Loader;
