import { useState, ReactElement, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HomeIcon from "@mui/icons-material/Home";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HistoryIcon from "@mui/icons-material/History";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Link } from "react-router-dom";

type ItemProps = {
    title: string;
    to: string;
    icon: ReactElement;
    selected: string;
    setSelected: React.Dispatch<React.SetStateAction<string>>;
};

const Item = ({ title, to, icon, selected, setSelected }: ItemProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Link to={to}>
            <MenuItem
                active={selected === title}
                style={{ color: colors.grey[100] }}
                onClick={() => setSelected(title)}
                icon={icon}
            >
                <Typography>{title}</Typography>
            </MenuItem>
        </Link>
    );
};

interface SidebarProps {
    isSmallScreen: boolean;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const AgentSidebar = ({
    isSmallScreen,
    isCollapsed,
    setIsCollapsed,
    isHidden,
    setIsHidden
}: SidebarProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");

    const handleMenuClick = () => {
        if (isSmallScreen) {
            setIsHidden(true);
        }
        else{
            setIsCollapsed(!isCollapsed)
        }
    };

    useEffect(() => {
        setIsHidden(isSmallScreen)
    }, [isSmallScreen]);

    return (
        <Box
            className="min-h-max"
            sx={{
                "& .pro-sidebar-inner": {
                    background: `${colors.primary[400]} !important`,
                },
                "& .pro-icon-wrapper": {
                    backgroundColor: "transparent !important",
                },
                "& .pro-inner-item": {
                    padding: "5px 35px 5px 20px !important",
                },
                "& .pro-inner-item:hover": {
                    color: `${colors.greenAccent[500]} !important`,
                },
                "& .pro-menu-item.active": {
                    color: `${colors.greenAccent[800]} !important`,
                },
            }}
        >
            {!isHidden && (
                <Sidebar  collapsed={isCollapsed}>
                    <Menu>
                        <MenuItem
                            onClick={handleMenuClick}
                            icon={
                                isCollapsed ? <MenuOutlinedIcon /> : undefined
                            }
                            style={{
                                margin: "10px 0 20px 0",
                                color: colors.grey[100],
                                display: "block",
                            }}
                        >
                            {!isCollapsed && (
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    ml="15px"
                                >
                                    <Typography
                                        variant="h3"
                                        color={colors.grey[100]}
                                    >
                                        Gidra AI
                                    </Typography>
                                    <IconButton
                                        onClick={() =>
                                            setIsCollapsed(!isCollapsed)
                                        }
                                    >
                                        <MenuOutlinedIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </MenuItem>

                        {/* MENU ITEMS */}
                        <Box paddingLeft={isCollapsed ? undefined : "0%"}>
                            <Item
                                title="Home"
                                to="/hub"
                                icon={<HomeIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Automated runs"
                                to="/automation"
                                icon={<AutoFixHighIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="History of runs"
                                to="/history"
                                icon={<HistoryIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Secrets"
                                to="/secrets"
                                icon={<KeyIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="My fuel sources"
                                to="/fuel_sources"
                                icon={<LocalGasStationIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="My teams"
                                to="/team"
                                icon={<GroupsIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                            <Item
                                title="Feedback or request"
                                to="/feedback"
                                icon={<FeedbackIcon />}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </Box>
                    </Menu>
                </Sidebar>
            )}
        </Box>
    );
};

export default AgentSidebar;
