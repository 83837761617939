import { ReactElement } from 'react';
import { UserInfo } from "firebase/auth";
import { PipelineStepType } from "../types/PlConfig";

const apiUrl = process.env.REACT_APP_ENVIRONMENT === "local" ? "http://localhost:8080" : "https://api.gidra.ai";


const ANSI_PATTERN = /\u001b\[(\d+)(;\d+)?(;\d+)?(;\d+)?[A-Za-z]/g;

function codeToColor(code: number): string | undefined {
    const colorMap: Record<number, string> = {
        30: 'black',
        31: 'red',
        32: 'green',
        //33: 'yellow',
        // yellow is not readable in light color scheme
        33: 'magenta',
        34: 'blue',
        35: 'magenta',
        //36: 'cyan',
        // Replacing cyan with blue because cyan is not readable in light color scheme
        36: 'blue',
        37: 'white',
    };

    const color = colorMap[code];
    return color || undefined;
}

function codeToBackgroundColor(code: number): string | undefined {
    const bgColorMap: Record<number, string> = {
        40: 'black',
        41: 'red',
        42: 'green',
        43: 'yellow',
        44: 'blue',
        45: 'magenta',
        46: 'cyan',
        47: 'white',
    };

    const bgColor = bgColorMap[code];
    return bgColor || undefined;
}

function ansiToReact(text: string): ReactElement[] {
    const elements: ReactElement[] = [];
    let style: React.CSSProperties = {};
    let lastIndex = 0;

    const applyStyles = (match: RegExpExecArray) => {
        if (lastIndex < match.index) {
            elements.push(
                <span key={lastIndex} style={style}>
                    {text.slice(lastIndex, match.index)}
                </span>
            );
        }
        const codes = match[1].split(';').map((code) => parseInt(code, 10));
        codes.forEach((code) => {
            if (code === 0) {
                style = {};
            } else {
                const color = codeToColor(code);
                if (color !== undefined) {
                    style.color = color;
                }

                // For some reason changing background color is breaking everything, commenting out for now.
                //const bgColor = codeToBackgroundColor(code);
                //if (bgColor !== undefined) {
                //  style.backgroundColor = bgColor;
                //}
            }
        });
        lastIndex = match.index + match[0].length;
    };

    let match;
    while ((match = ANSI_PATTERN.exec(text)) !== null) {
        applyStyles(match);
    }

    if (lastIndex < text.length) {
        elements.push(
            <span key={lastIndex} style={style}>
                {text.slice(lastIndex)}
            </span>
        );
    }

    return elements;
}

function parseNumberOrDefault(input?: string, defaultValue: number = 5): number {
    input = input || '';
    return isNaN(parseInt(input, 10)) ? defaultValue : parseInt(input, 10);
}

const getCurrentOrigin = () => {
    if (typeof window !== 'undefined') {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? ':' + port : ''}`;
    } else {
        console.error('Origin can only be obtained on the client-side.');
        return '';
    }
};

const operatorToOauth: { [key: string]: string[] } = {
    "Tweet": ["twitter.com"],
    "Create GitHub merge requests": ["github.com"],
    "Get files from GitHub": ["github.com"],
    "Write documentation to GitHub": ["github.com"]
}

const providerDataUrl: {[key: string] : string} = {
    "twitter.com": "Twitter",
    "github.com": "GitHub"
}

// TODO(Misha): replace with logic that would pull from backend using declare_secrets.
function pipelineRequiresAuth(steps: PipelineStepType[], userInfo: UserInfo[] | undefined): string[] {
    let pipelineProviders: string[] = [];
    let userInfoSet = new Set<string>();
    steps.forEach((step) => {
      if (operatorToOauth[step.operator]) {
        pipelineProviders.push(...operatorToOauth[step.operator]);
      }
    });

    userInfo?.forEach((info) => {
        userInfoSet.add(info.providerId)
    })

    // Convert pipelineProviders to their names and then filter out the ones present in userInfo
    return Array.from(new Set(pipelineProviders))
        .map(provider => providerDataUrl[provider])
        .filter(provider => !Array.from(userInfoSet).map(id => providerDataUrl[id]).includes(provider));
}

async function fetch_with_auth(path: string, id_token: string, method: string, body: any = null) {
    const r: RequestInit = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + id_token,
        },
    };

    if (method !== 'GET' && method !== 'HEAD' && body !== null) {
        r.body = JSON.stringify(body);
    }

    const response = await fetch(
        `${apiUrl}/${path}`, r
    );

    if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Calling '${path} (${method})' returned an error: ${errorData}`);
    }

    const data = await response.json();

    if ('success' in data && !data.success) {
        throw new Error(data.error);
    }

    return data;
}

export function setCurrentUrlParam(param_name : string, value : string) {
    const currentUrl = new URL(window.location.href);
    const searchParams = new URLSearchParams(currentUrl.search);
    searchParams.set(param_name, value);
    currentUrl.search = searchParams.toString();
    // Update the browser's address bar without triggering a page refresh
    window.history.pushState({}, "", currentUrl.toString());
}

export function validateLettersNumbersOnly(name : string) {
  // regular expression for English letters, underscores, dashes and numbers
  const re = /^[a-zA-Z0-9_-]+$/;
  return re.test(name);
}

export { apiUrl, ansiToReact, parseNumberOrDefault, getCurrentOrigin, pipelineRequiresAuth, fetch_with_auth };

