import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import Layout from "./components/Layout";
import "./styles/index.css";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { ScreenSizeProvider } from "./context/ScreenSizeContext";

import SpaceEditorPage from './pages/space_editor';
import SpacePage from './pages/space';
import HistoryPage from './pages/history';
import HubPage from './pages/hub';
import FuelSourcesPage from './pages/fuel_sources';
import PipelinePage from './pages/pipeline';
import TeamPage from './pages/team';
import FeedbackPage from './pages/feedback';
import AutomationPage from './pages/automation';
import SecretsPage from './pages/secrets';
import HomePage from './pages/home_page';
import PrivacyPolicy from './pages/privacy_policy';
import TermsOfService from './pages/terms_of_service';

function App() {
    useEffect(() => {
        const uid = Cookies.get("__UID");
        if (!uid) {
            const newUid = uuidv4();
            Cookies.set("__UID", newUid, {
                sameSite: "strict",
                expires: 365,
            });
        }
    }, []);
    
    const [theme, colorMode] = useMode();
    
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ScreenSizeProvider>
                    <Router>
                        <Layout>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/space_editor" element={<SpaceEditorPage />} />
                                <Route path="/space" element={<SpacePage />} />
                                <Route path="/history" element={<HistoryPage />} />
                                <Route path="/hub" element={<HubPage />} />
                                <Route path="/fuel-sources" element={<FuelSourcesPage />} />
                                <Route path="/pipeline" element={<PipelinePage />} />
                                <Route path="/team" element={<TeamPage />} />
                                <Route path="/feedback" element={<FeedbackPage />} />
                                <Route path="/automation" element={<AutomationPage />} />
                                <Route path="/secrets" element={<SecretsPage />} />
                                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                                <Route path="/terms_of_service" element={<TermsOfService />} />
                            </Routes>
                            <Toaster />
                        </Layout>
                    </Router>
                </ScreenSizeProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>

    );
}

export default App;

