import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    Box,
    IconButton,
    Container,
    Link,
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../auth/firebase";
import { UserProfile } from "../types/UserProfile";
import { apiUrl } from "../components/Util";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from '../components/Header';
import toast from "react-hot-toast";
import moment from "moment";
import Loader from "../components/Loader";

interface Trigger {
    all_run_ids: any[];
    created_ts: string;
    events: any[];
    extra_info: {
        attempt_deadline: string;
        description: string;
        last_attempt_time: string;
        name: string;
        retry_config: string;
        schedule: string;
        schedule_time: string;
        state: number;
        status: string;
        time_zone: string;
        user_update_time: string;
    };
    name: string;
    pl_config_hash: string;
    state: string;
    trigger_id: string;
    trigger_type: string;
    user_id: string;
}

const AutomationPage: React.FC = () => {
    const [data, setData] = useState<Trigger[]>([]);
    const [openIndex, setOpenIndex] = useState(-1);
    const [userProfile, setUserProfile] = useState<UserProfile>(
        new UserProfile({ user_id: "" })
    );
    const [isLoading, setIsLoading] = useState(false);

    const handleRowClick = (index: number) => {
        setOpenIndex(openIndex === index ? -1 : index);
    };

    const fetchAutomations = async (user_id: string) => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `${apiUrl}/trigger?user_id=${user_id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(
                    `Error ${response.status}: ${response.statusText}`
                );
            }

            const fetchedData = await response.json();
            setData(fetchedData);
        } catch (error) {
            console.error("Error:", error);
        }
        setIsLoading(false);
    };

    const handleDelete = async ( name: string, trigger_id: string) => {
        try {
            toast.loading("Deleting automation...");
            console.log(name)
            console.log(userProfile.user_id)
            const response = await fetch(
                `${apiUrl}/trigger`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        trigger: {
                            name: name,
                            user_id: userProfile.user_id,
                            trigger_id: trigger_id,
                        },
                    }),
                }
            );
    
            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }
    
            // Remove the deleted item from the state
            const updatedData = data.filter(item => item.name !== name);
            setData(updatedData);
    
            // Show success toast
            toast.dismiss();
            toast.success(`Automation deleted successfully.`);
        } catch (error) {
            // Show error toast
            toast.dismiss();
            toast.error("Error deleting the automation.");
            console.error("Error:", error);
        }
    };
    

    useEffect(() => {
        const auth = getAuth(app);

        const refresh = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                fetchAutomations(up.user_id!);
            }
        });

        return () => {
            refresh();
        };
    }, []);

    const convertToLocalTime = (time: string) => {
        return moment.utc(time).local().format("YYYY-MM-DD HH:mm:ss");
    };

    // Moved DataList component here
    return (
        <Container className="min-h-screen min-w-full">
        <Header title="Automations" subtitle='All your scheduled/automated agents'></Header>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Number of Runs</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Extra Info</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, i) => (
                            <React.Fragment key={i}>
                                <TableRow
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                    onClick={() => handleRowClick(i)}
                                >
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>
                                    <TableCell>{item.all_run_ids.length}</TableCell> {/* New Cell */}
                                    <TableCell>{item.created_ts}</TableCell>
                                    <TableCell>
                                        {/* Display only a preview here */}
                                        {JSON.stringify(
                                            item.extra_info,
                                            null,
                                            2
                                        ).substring(0, 50) + "..."}
                                    </TableCell>
                                    {/* Add other columns here */}
                                    <TableCell>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(item.name, item.trigger_id);
                                            }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                        }}
                                        colSpan={6}
                                    >
                                        <Collapse
                                            in={openIndex === i}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <Box margin={1}>
                                                <h3>Extra Info:</h3>
                                                <pre>
                                                    {/* Convert time fields in extra info to local time before displaying */}
                                                    {JSON.stringify(
                                                        {
                                                            ...item.extra_info,
                                                            last_attempt_time: convertToLocalTime(item.extra_info.last_attempt_time),
                                                            next_attempt_time: convertToLocalTime(item.extra_info.schedule_time),
                                                            user_update_time: convertToLocalTime(item.extra_info.user_update_time),
                                                        },
                                                        null,
                                                        2
                                                    )}
                                                </pre>
                                            </Box>
                                            <Box margin={1}>
                                                <h3>Run History:</h3>
                                                {item.all_run_ids.map((run_id, index) => (
                                                    <Link
                                                        key={index}
                                                        href={`/pipeline?run_id=${run_id}`}
                                                        target="_blank"
                                                        sx={{ display: 'block', color: 'blue' }}  // Add display block for new lines and color blue for link color
                                                    >
                                                        {run_id}
                                                    </Link>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isLoading && (  // If isLoading is true, show the Loader
                <div className="flex flex-row p-4 min-w-full content-center justify-center">
                    <Loader size={25}/>
                </div>
            )}
        </Container>
    );
};

export default AutomationPage;
