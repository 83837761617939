import { Box, Button, Card, IconButton, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { tokens } from "../theme";
import { apiUrl } from "./Util";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileList from "./FileList";
import { getAuth } from "firebase/auth";
import app from "../auth/firebase";
import { UserProfile } from "../types/UserProfile";


interface FileSelectorProps {
    userProfile: UserProfile;
    onSelectedFilesChange: (selectedFiles: string[]) => void;
}

const FileSelector: React.FC<FileSelectorProps> = ({
    userProfile,
    onSelectedFilesChange,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [files, setFiles] = useState<string[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [expanded, setExpanded] = useState(false);
    const auth = getAuth(app);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    async function uploadFile(file: File, user_id: string ) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("user_id", user_id);
    
        const response = await fetch(`${apiUrl}/upload_file`, {
            method: "POST",
            headers: {
            },
            body: formData,
        });
    
        if (!response.ok) {
            throw new Error("File upload failed");
        }
    
        return await response.json();
    }
    

    const onSelectFile = async () => {
        console.log("onSelectFile called");
        if (!inputRef.current?.files?.[0]) return;
        const file = inputRef.current.files[0];
        inputRef.current.value = ""; // clear files
    
        try {
            await handleFileUpload(file);
        } catch (error: any) {
            console.error("File upload failed:", error.message);
        }
    };
    
    const fetchFiles = async () => {
        if (userProfile.user_id) {
            const response = await fetch(`${apiUrl}/list_all_files?user_id=${userProfile.user_id}`, {
                headers: {
                },
            });
        
            if (!response.ok) {
                throw new Error("Failed to fetch files");
            }
        
            const data = await response.json();
            return data.files; // Return the updated files list
        }
    };
    
    const handleFileUpload = async (file: File) => {
        try {
            if (!userProfile.user_id) {
                console.error("User ID is missing");
                return;
            }
            const response = await uploadFile(file, userProfile.user_id);
            console.log(response);
            // Refresh the files list after successful upload
            const updatedFiles = await fetchFiles();
            setFiles(updatedFiles.filter((f : any) => f !== file.name)); // Update 'files' state without the uploaded file
    
            // Add the uploaded file to selectedFiles
            const newSelectedFiles = [...selectedFiles, file.name];
            setSelectedFiles(newSelectedFiles);
            onSelectedFilesChange(newSelectedFiles);
        } catch (error: any) {
            console.error("File upload failed:", error.message);
        }
    };

    useEffect(() => {
        fetchFiles()
        .then((fetchedFiles) => setFiles(fetchedFiles))
        .catch((error) => {
            console.error("Error fetching files:", error.message);
        });
    }, [userProfile.user_id]);

    const onDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        fileName: string
    ) => {
        e.dataTransfer.setData("fileName", fileName);
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const onDelete = async (fileName: string) => {
        try {
            const response = await fetch(`${apiUrl}/delete_file?user_id=${userProfile.user_id}&file_name=${fileName}`, {
                method: "DELETE",
                headers: {
                },
            });
    
            if (!response.ok) {
                throw new Error("Failed to delete file");
            }
    
            // Remove the file from the files list
            setFiles(files.filter((file) => file !== fileName));
        } catch (error: any) {
            console.error("Error deleting file:", error.message);
        }
    };
    

    const onDeleteDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const fileName = e.dataTransfer.getData("fileName");
        onDelete(fileName);
    };

    const onDrop = (e: React.DragEvent<HTMLDivElement>, target: string) => {
        const fileName = e.dataTransfer.getData("fileName");
        if (target === "use" && !selectedFiles.includes(fileName)) {
            const newSelectedFiles = [...selectedFiles, fileName];
            setSelectedFiles(newSelectedFiles);
            onSelectedFilesChange(newSelectedFiles);
            setFiles(files.filter((file) => file !== fileName));
        } else if (target === "files" && !files.includes(fileName)) {
            const newFiles = [...files, fileName];
            setFiles(newFiles);
            setSelectedFiles(selectedFiles.filter((file) => file !== fileName));
        }
    };

    return (
        <div className="flex flex-col min-w-full items-start p-2 space-y-3">
            <div className="flex flex-row justify-center items-center">
                <h1 className=" justify-center">{"Edit Your Agent's Workspace"}</h1>
                <IconButton
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    sx={{
                        color: colors.primary[100],
                        transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                        marginLeft: "auto",
                        transition: theme.transitions.create("transform", {
                            duration: theme.transitions.duration.shortest,
                        }),
                        marginTop: "0px",
                    }}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>

            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className="min-w-full"
            >
                <div className="flex flex-col min-w-full">
                    <div className="flex flex-col space-y-3 min-w-full">
                        <div className="min-w-full">
                            <Card
                                className="p-4 m-2 min-w-full rounded-lg w-auto space-y-3"
                                sx={{
                                    backgroundColor: colors.primary[400],
                                }}
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, "files")}
                            >
                                <h2 className="text-lg font-semibold mb-2">
                                    Your Uploaded Files
                                </h2>

                                <div className="w-full">
                                    <FileList
                                        userProfile={userProfile}
                                        files={files}
                                        onDragStart={onDragStart}
                                    />
                                </div>

                                <div className="flex flex-row items-center justify-between space-x-3 w-full ">
                                    <div className="relative">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                inputRef.current?.click()
                                            }
                                            sx={{
                                                marginTop: "0.2rem",
                                                color: `${colors.primary[100]} !important`,
                                                background: `${colors.primary[400]} !important`,
                                                "&:hover": {
                                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                                },
                                            }}
                                        >
                                            <UploadFileIcon
                                                sx={{ marginRight: "0.5rem" }}
                                            />
                                            Upload File
                                        </Button>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            name="file"
                                            style={{ display: "none" }}
                                            ref={inputRef}
                                            onChange={onSelectFile}
                                        />

                                        <Box
                                            className="flex flex-row items-end space-x-2 border rounded-md"
                                            onDragOver={onDragOver}
                                            onDrop={onDeleteDrop}
                                            sx={{
                                                padding: "0.3rem",
                                            }}
                                        >
                                            <DeleteIcon />
                                            <span className="mt-2">Drag here to delete</span>
                                        </Box>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="m-2">
                            <Card
                                className="p-4 m-2 rounded-lg"
                                sx={{ backgroundColor: colors.primary[400] }}
                                onDragOver={onDragOver}
                                onDrop={(e) => onDrop(e, "use")}
                            >
                                <h2 className="text-lg font-semibold mb-2">
                                    {"Your Agent's Workspace"}
                                </h2>
                                <FileList
                                    userProfile={userProfile}
                                    files={selectedFiles}
                                    onDragStart={onDragStart}
                                />
                            </Card>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default FileSelector;
