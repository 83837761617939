import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Topbar from "../global/Topbar";
import AgentSidebar from "../global/AgentSidebar";
import Footer from "../components/Footer";
import { useIsSmallScreen } from "../context/ScreenSizeContext";

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const isSmallScreen = useIsSmallScreen();
    const location = useLocation();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
    const [isHidden, setIsHidden] = useState(true);

    const isLandingRoute = location.pathname === "/";

    return (
        <div id="app" className="flex flex-col min-h-screen relative w-full">
            <div className="flex flex-grow">
                {!isLandingRoute && (
                    <AgentSidebar
                        isSmallScreen={isSmallScreen}
                        isCollapsed={isSidebarCollapsed}
                        setIsCollapsed={setIsSidebarCollapsed}
                        isHidden={isHidden}
                        setIsHidden={setIsHidden}
                    /> 
                )}
                <div className="flex flex-col w-full">
                    <Topbar
                        isSmallScreen={isSmallScreen}
                        isLandingRoute={isLandingRoute}
                        isCollapsed={isSidebarCollapsed}
                        setIsCollapsed={setIsSidebarCollapsed}
                        isHidden={isHidden}
                        setIsHidden={setIsHidden}
                    />
                    <div className="flex-grow">{children}</div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Layout;
