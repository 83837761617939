import React, { useState, Dispatch, SetStateAction } from "react";
import Button from "@mui/material/Button";
import { Box, TextField, useTheme } from "@mui/material";
import Popup from "reactjs-popup";
import { getAuth } from "firebase/auth";
import app from "../auth/firebase";
import { apiUrl, pipelineRequiresAuth } from "../components/Util";
import { tokens } from "../theme";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { PlConfig } from "../types/PlConfig";
import { UserProfile } from "../types/UserProfile";
import { SavedItem } from '../types/SavedItem';


interface ScheduleAgentButtonProps {
    plConfig: PlConfig;

    userProfile: UserProfile;
    setUserProfile: Dispatch<SetStateAction<UserProfile>>;

    savedItem: SavedItem;

    authRequired: boolean;
    setAuthRequired: Dispatch<SetStateAction<boolean>>;

    providers: string[];
    setProviders: Dispatch<SetStateAction<string[]>>;
}

function ScheduleAgentButton(props: ScheduleAgentButtonProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const auth = getAuth(app);
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [cronJob, setCronJob] = useState("");

    const closeModal = () => setOpen(false);
    const handlePopupOpen = () => {
        if (!auth.currentUser) {
            toast.error("Please log in to schedule");
            return;
        }

        const requiredProviders = pipelineRequiresAuth(
            props.plConfig.pipeline,
            auth.currentUser?.providerData
        );

        // If this pipeline requires any sort of account linking (twitter, github, etc.) Force the user to sign in.
        if (!auth.currentUser) {
            toast.error("Sign in to schedule an pipeline!");
            return;
        }
        if (requiredProviders.length > 0) {
            props.setProviders(requiredProviders);
            props.setAuthRequired(true);
            return;
        }

        setOpen(true);
    };

    const scheduleAgent = async () => {
        try {
            toast.loading("Automating your pipeline...");

            // TODO(Misha): move this to UserProfile properly
            const response = await fetch(`${apiUrl}/trigger`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },

                body: JSON.stringify({
                    pl_config: {
                        ...props.plConfig,
                    },
                    user_profile: {
                        ...props.userProfile,
                    },
                    trigger: {
                        user_id: props.userProfile.user_id,
                        name: title,
                        pl_config_hash: props.plConfig.pl_config_hash,
                        trigger_type: "SCHEDULED",
                        state: "Created",
                        all_run_ids: [],
                        events: [],
                    },
                    cron: cronJob,
                }),
            });

            const jsonResponse = await response.json();
            if (!response.ok) {
                const errorMessage = jsonResponse.error;
                if (errorMessage.includes("Fuel Source")) {
                    // If fuel source appears to be invalid then removing it from the cookies
                    // and state variable so that to make user use open AI token directly.
                    Cookies.remove("__FSID");
                    props.userProfile.update(props.setUserProfile, {
                        fuel_source_id: "",
                        openai_fuel_preference: "token",
                    });
                }
                toast.dismiss();
                toast.error(errorMessage, { duration: 8000 });
                return;
            }

            toast.dismiss();
            toast.success(`Agent Automation succeeded!`);
            closeModal();
        } catch (e) {
            toast.dismiss();
            toast.error(`Failed to automate your pipeline! ${e}`);
        }
    };

    const contentStyle = {
        background: colors.primary[400],
        borderRadius: "20px",
        width: "300px",
        height: "300px",
        border: "1px solid",
        borderColor: colors.primary[200],
    };

    return (
        <div className="!min-w-full">
            <Button
                className="!min-w-full"
                onClick={handlePopupOpen}
                sx={{
                    backgroundColor: "transparent",
                    color: colors.primary[200],
                    border: "1px solid",
                    borderColor: colors.primary[200],
                    width: `100% !important`,
                    "&:hover": {
                        backgroundColor: `${colors.grey[800]} !important`,
                        cursor: "pointer",
                    },
                }}
            >
                Automate
            </Button>

            <Popup
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
                contentStyle={contentStyle}
            >
                <Box className="w-full space-y-5" p={3}>
                    <h5>Name your automation and choose its frequency</h5>
                    <TextField
                        label="Automation Name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        type="number"
                        label="Run every _ hour(s)"
                        value={cronJob}
                        onChange={(e) => setCronJob(e.target.value)}
                        inputProps={{
                            min: 0,
                            max: 24,
                        }}
                        fullWidth
                    />
                    <div className="flex justify-end space-x-2">
                        <Button
                            onClick={closeModal}
                            sx={{
                                backgroundColor: `${colors.redAccent[700]} !important`,
                                color: "white",
                                "&:hover": {
                                    backgroundColor: `${colors.redAccent[800]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                backgroundColor: `${colors.greenAccent[500]} !important`,
                                color: "white",
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[600]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                            onClick={scheduleAgent}
                        >
                            Submit Automation
                        </Button>
                    </div>
                </Box>
            </Popup>
        </div>
    );
}

export default ScheduleAgentButton;
