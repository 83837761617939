import React from 'react';
import ReactJson from 'react-json-view';
import { Edge } from 'reactflow';

interface EdgeDataPopupProps {
    edge: Edge;
    stepOutputs: Record<string, Record<string, any>>;
}

const EdgeDataPopup: React.FC<EdgeDataPopupProps> = ({ edge, stepOutputs }) => {
    const stepId = typeof edge.source === 'string' ? edge.source : '';
    const outputHandle = edge.sourceHandle ?? 'defaultHandle';
    const stepOutput = stepOutputs[stepId];
    const outputData = stepOutput ? stepOutput[outputHandle] : null;

    return (
        <div className="edge-popup" style={{
            position: 'fixed',
            right: 0,
            top: 0,
            width: '40%',
            height: '100%',
            overflowY: 'auto',
            backgroundColor: 'white',
            boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 1000, // Ensure it's above other elements
        }}>
            {outputData && (
                <ReactJson 
                    src={outputData}
                    theme="rjv-default"
                    collapsed={false}
                    shouldCollapse={false}
                    name={null} // to avoid the root name being displayed
                    displayDataTypes={false}
                    displayObjectSize={false}
                />
            )}
        </div>
    );
};

export default EdgeDataPopup;
