import {
    Card,
    FormControl,
    Select,
    MenuItem,
    TextField,
    useTheme,
    Collapse,
    Switch,
    FormControlLabel,
    InputLabel,
} from "@mui/material";
import React, { useState, Dispatch, SetStateAction } from "react";
import { tokens } from "../theme";

import { PlConfig } from "../types/PlConfig";

interface ExtraSettingsProps {
    plConfig: PlConfig;
    setPlConfig: Dispatch<SetStateAction<PlConfig>>;
}

function ExtraSettings(props: ExtraSettingsProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const nonStandardSettingPresent = () => {
        if (props.plConfig.temperature !== 1.0) return true;
        if (props.plConfig.preferred_model !== 'gpt-3.5-turbo') return true;
        if (props.plConfig.memory_objects.some(el => el.startsWith('db:'))) return true;
        return false;
    };

    const [expanded, setExpanded] = useState(nonStandardSettingPresent());

    const handleExtraSettingsToggle = (event: any) => {
        setExpanded(!expanded);
    };

    const handleModelChange = (event: any) => {
        props.plConfig.update(props.setPlConfig, { preferred_model: event.target.value });
    };

    const handleTemperatureChange = (event: any) => {
        props.plConfig.update(props.setPlConfig, { temperature: event.target.value });
    };

    const handleDBChange = (event: any) => {
        const newDB = `db:${event.target.value}`;
        const otherObjects = props.plConfig.memory_objects?.filter((obj) => !obj.startsWith("db:")) || [];
        props.plConfig.update(props.setPlConfig, { memory_objects: [...otherObjects, newDB] });
    };
    
    function getSelectedDB(memoryObjects: string[] | null) {
        if (memoryObjects) {
            for (let i = 0; i < memoryObjects.length; i++) {
                if (memoryObjects[i].startsWith("db:")) {
                    return memoryObjects[i].substring(3);
                }
            }
        }
        return ''; 
    }   


    const selectedDB = props.plConfig.memory_objects?.find((obj) => obj.startsWith("db:"))?.substring(3) || "";


    
    return (
        <div>
            <div className="flex flex-row justify-start items-center">
                <FormControlLabel
                    control={
                        <Switch
                            checked={expanded}
                            onChange={handleExtraSettingsToggle}
                            name="Extra-settings-toggle"
                        />
                    }
                    label="Use Extra Settings"
                    sx={{
                        "& .MuiSwitch-thumb": {
                            backgroundColor: expanded
                                ? colors.greenAccent[800]
                                : colors.primary[200],
                        },
                        "& .MuiSwitch-track": {
                            backgroundColor: expanded
                                ? colors.primary[200]
                                : colors.primary[100],
                        },
                    }}
                />
            </div>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                className="min-w-full"
            >
                <Card
                    className="flex flex-col space-y-5 border p-4 rounded-sm"
                    sx={{ borderColor: colors.primary[300], backgroundColor: colors.primary[400] }}
                >
                    <FormControl>
                        <InputLabel id="db-select-label">Select Model</InputLabel>
                        <Select
                            label='Select Model'
                            value={props.plConfig.preferred_model}
                            onChange={handleModelChange}
                            name="model-selector"
                        >
                            <MenuItem value={"gpt-4"}>GPT-4</MenuItem>
                            <MenuItem value={"gpt-3.5-turbo"}>GPT-3.5-Turbo</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <InputLabel id="db-select-label">Select Database</InputLabel>
                        <Select
                            label='Select Database'
                            value={getSelectedDB(props.plConfig.memory_objects)}
                            onChange={handleDBChange}
                            name="db-selector"
                        >
                            <MenuItem value={"none"}>None</MenuItem>
                            <MenuItem value={"pinecone"}>Pinecone</MenuItem>
                            <MenuItem value={"weaviate"}>Weaviate</MenuItem>
                            <MenuItem value={"redis"}>Redis</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="flex flex-row justify-start items-center">
          
                        <TextField
                            className="flex-1 min-w-3/4"
                            label="Temperature"
                            type="number"
                            inputProps={{ min: 0, max: 2, step: 0.10 }}
                            value={props.plConfig.temperature}
                            onChange={handleTemperatureChange}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                </Card>
            </Collapse>
        </div>
    );
}

export default ExtraSettings;
