import { Dispatch, SetStateAction } from "react";

import { PlConfig } from "./PlConfig";
import {
    apiUrl,
    fetch_with_auth
} from "../components/Util";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { UserProfile } from './UserProfile';

export class PlRun {
    pl_config_hash: string;
    run_id: string;
    state: string;
    created_ts: Date | null;
    finished_ts: Date | null;
    openai_token_hash: string | null;
    fuel_source_id: string | null;
    user_id: string;
    memory_objects_written: string[] | null;
    log: string[] | null;
    step_outputs: Record<string, Record<string, any>> | null;

    constructor(plRun: Partial<PlRun>) {
        this.pl_config_hash = plRun.pl_config_hash!;
        this.run_id = plRun.run_id!;
        this.state = plRun.state!;
        this.created_ts = plRun.created_ts instanceof Date ? plRun.created_ts : (plRun.created_ts ? new Date(plRun.created_ts) : null);
        this.finished_ts = plRun.finished_ts instanceof Date ? plRun.finished_ts : (plRun.finished_ts ? new Date(plRun.finished_ts) : null);
        this.openai_token_hash = plRun.openai_token_hash || null;
        this.fuel_source_id = plRun.fuel_source_id || null;
        this.user_id = plRun.user_id!;
        this.memory_objects_written = plRun.memory_objects_written || null;
        this.log = plRun.log || null;
        if (typeof plRun.step_outputs === 'string') {
            this.step_outputs = JSON.parse(plRun.step_outputs);
        } else {
            this.step_outputs = plRun.step_outputs || null;
        }
    }

    update(
        setState: React.Dispatch<React.SetStateAction<PlRun>>,
        someValues: Partial<PlRun>
    ) {
        const { log, state, ...rest } = someValues;

        let updatedLog: string[] = log || [];

        if (log && state) {
            updatedLog = [...log, `State: ${state}`];
        }

        const newInstance = new PlRun({
            ...this,
            ...rest,
            log: updatedLog,
        });

        setState(newInstance);
    }
    
    isRunning(): boolean {
        return this.state === "RUNNING" || this.state === "TERMINATING" || this.state === "STARTED";
    }
    
    static async loadRun(run_id: string): Promise<PlRun> {
        const response = await fetch(`${apiUrl}/plrun?run_id=${run_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const plRun = new PlRun(data);

        return plRun;
    }

    static async loadRunAndConfig(run_id: string): Promise<{ plRun: PlRun, plConfig: PlConfig }> {
        const response = await fetch(`${apiUrl}/plrun_plconfig?run_id=${run_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        // Use constructors to create instances
        const plRun = new PlRun(data.pl_run);
        const plConfig = new PlConfig(data.pl_config);

        return { plRun, plConfig };
    }

    static async startRun(
        plConfig: PlConfig, 
        userProfile: UserProfile, 
        setUserProfile: Dispatch<SetStateAction<UserProfile>>,
        idToken: string) {
        const newPlRun = {
            log: [],
            state: "STARTED",
            pl_config_hash: await plConfig.calculate_hash(),
            run_id: "",
        };

        const data = await fetch_with_auth('/start_agent', idToken, 'POST', {
            pl_run: {
                ...newPlRun,
            },
            pl_config: {
                ...plConfig,
            },
            user_profile: {
                ...userProfile,
            },
        });

        newPlRun.run_id = data.run_id;
        return new PlRun(newPlRun);
    }
}
