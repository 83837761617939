import React from 'react';
import { IconButton, Tooltip, Box, Typography } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const GithubLink: React.FC = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const repoUrl = 'https://github.com/gidra-ai/gidra_nodes';

    return (
        <Tooltip title="View on GitHub">
            <Box 
                onClick={() => window.open(repoUrl, "_blank")}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '4px',
                    padding: '5px',
                    border: '1px solid',
                    background: colors.primary[400],
                    borderColor: colors.primary[100],
                    cursor: 'pointer',
                }}
                className="flex flex-row space-x-2"
            >
                <GitHubIcon />
                <Typography variant="body1">View on Github</Typography>
            </Box>
        </Tooltip>
    );
};

export default GithubLink;
