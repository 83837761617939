import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Typography, useTheme } from "@mui/material";
import { useSpring, animated } from "react-spring";
import BuildIcon from "@mui/icons-material/Build";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ShareIcon from "@mui/icons-material/Share";
import { tokens } from "../theme";
import GithubLink from "../components/GithubLink";
import FeatureBox from "../components/FeatureBox";

function HomePage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const props = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        delay: 400,
    });

    const handleGetStartedButtonClick = () => {
        window.location.href = `${window.location.origin}/hub`;
    };

    return (
        <div className="min-h-screen min-w-full flex justify-center">
            <div className="flex flex-col min-w-full items-center space-y-6 pt-10">
                <div className="flex flex-col space-y-6">
                    <div className="flex flex-col text-3xl md:text-5xl items-center justify-center space-y-3">
                        <h1 className="font-thin">
                            <span style={{ color: colors.blueAccent[500] }}>
                                Platform for creation of AI apps
                            </span>
                        </h1>

                    </div>
                    <div className="flex text-xs md:text-[14px] justify-center ">
                        <div className="w-[350px] md:w-[600px] space-y-2">
                            <h2 className="text-center">
                               We handle the infra so you can focus on building useful things with LLMs
                            </h2>
                        </div>
                    </div>

                    <div className="flex justify-center mt-4 space-x-4">
                        <Button
                            onClick={handleGetStartedButtonClick}
                            style={{
                                backgroundColor: colors.greenAccent[900],
                            }}
                            sx={{
                                border: `1px solid ${colors.greenAccent[300]}`,
                                color: colors.greenAccent[300],
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]}`,
                                    cursor: "pointer",
                                },
                            }}
                            
                        >
                            Get Started
                            <NorthEastIcon />
                        </Button>
                        <GithubLink />
                    </div>

                    <br />
                    <br />
                    <div className="relative" style={{width:'100%'}}>
                        <animated.div style={props}>
                            <Card
                                className="w-[0px] md:w-[345px]"
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                    position: "absolute",
                                    zIndex: 1,
                                    left: "-180px", // Moves the card to the left
                                    top: "150px",
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="400"
                                    image="images/output.png"
                                />
                            </Card>
                        </animated.div>
                        <animated.div style={props}>
                            <Card
                                className="w-[350px] md:w-[745px]"
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                    position: "relative",
                                    zIndex: 1,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="400"
                                    image="images/graph.png"
                                />
                            </Card>
                        </animated.div>
                        <animated.div style={props}>
                            <Card
                                className="w-[100px] md:w-[200px] left-[270px] top-[-200px] md:left-[730px] md:top-[-400px]"
                                style={{
                                    borderRadius: 8,
                                    border: "1px solid black",
                                    boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                    position: "absolute",
                                    zIndex: 2,
                                    opacity: 1,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image="images/options.png"
                                />
                            </Card>
                        </animated.div>
                    </div>
                </div>
                <div className="min-h-[150px]">
                    {/* Using this for spacing between sections */}
                </div>

                <div className="flex flex-col md:flex-row justify-between w-3/4">
                    <FeatureBox
                        IconComponent={BuildIcon}
                        color={colors.blueAccent[500]}
                        title="Build"
                        description="Create any AI powered pipeline you can imagine with our open source operators"
                    />
                    <FeatureBox
                        IconComponent={RocketLaunchIcon}
                        color={colors.blueAccent[500]}
                        title="Deploy"
                        description="Instantly deploy your creations, all cloud hosting is handled for you"
                    />
                    <FeatureBox
                        IconComponent={ShareIcon}
                        color={colors.blueAccent[500]}
                        title="Share"
                        description="Publish your work and share with the community, friends or team members"
                    />
                </div>
                <div className="min-h-[100px]">
                    {/* Using this for spacing between sections */}
                </div>

                <div className="flex flex-col w-full justify-center items-center ">
                    <div className="w-2/3 space-y-14 mx-auto">
                        {/* <div className="flex flex-col md:flex-row items-center w-full">
                            <div className="w-full p-6">  
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Build Complex Pipelines
                                </Typography>
                                <Typography variant="body1">
                                    With our innovative and flexible platform, you can create advanced and complex pipelines tailored to your specific business needs. Boost your efficiency and optimize your processes, all in one place.
                                </Typography>
                            </div>

                            <div className="w-full">
                                <animated.div style={props}>
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: 8,
                                            border: "1px solid black",
                                            boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                            position: "relative",
                                            zIndex: 1,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image="images/pipeline_art.png"
                                        />
                                    </Card>
                                </animated.div>
                            </div>
                        </div> */}

                        <div className="flex flex-col-reverse md:flex-row items-center w-full">
                            <div className="w-full">
                                <animated.div style={props}>
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: 8,
                                            border: "1px solid black",
                                            boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                            position: "relative",
                                            zIndex: 1,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image="images/place_operator.gif"
                                        />
                                    </Card>
                                </animated.div>
                            </div>
                            <div className="w-full p-6">  {/* padding added for a little space around the text */}
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Drag and Drop
                                </Typography>
                                <Typography variant="body1">
                                    Building a pipeline only takes a few clicks. Use the open source building blocks to create powerful AI apps.
                                </Typography>
                            </div>

                            
                        </div>

                        <div className="flex flex-col-reverse md:flex-row-reverse items-center w-full">
                            <div className="w-full">
                                <animated.div style={props}>
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: 8,
                                            border: "1px solid black",
                                            boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                            position: "relative",
                                            zIndex: 1,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image="images/link_operator.gif"
                                        />
                                    </Card>
                                </animated.div>
                            </div>
                            <div className="w-full p-6">  {/* padding added for a little space around the text */}
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Link Operators
                                </Typography>
                                <Typography variant="body1">
                                    With our innovative and flexible platform, you can create advanced and complex pipelines tailored to your specific business needs. Boost your efficiency and optimize your processes, all in one place.
                                </Typography>
                            </div>
                        </div>

                        <div className="flex flex-col-reverse md:flex-row items-center w-full">
                            <div className="w-full">
                                <animated.div style={props}>
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderRadius: 8,
                                            border: "1px solid black",
                                            boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
                                            position: "relative",
                                            zIndex: 1,
                                        }}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="400"
                                            image="images/run_pipeline.gif"
                                        />
                                    </Card>
                                </animated.div>
                            </div>
                            <div className="w-full p-6">  {/* padding added for a little space around the text */}
                                <Typography variant="h2" component="h2" gutterBottom>
                                    Run your Pipeline
                                </Typography>
                                <Typography variant="body1">
                                    Running your AI powered pipeline only takes a click. We handle all the hosting and infrastructure for you.
                                </Typography>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="min-h-[30px]">
                    {/* Using this for spacing between sections */}
                </div>

                <div className="flex w-full content-middle justify-center">
                    <div className="w-1/2">
                        <Button
                            onClick={handleGetStartedButtonClick}
                            style={{
                                backgroundColor: colors.greenAccent[900],
                            }}
                            sx={{
                                width: '100%',
                                border: `1px solid ${colors.greenAccent[300]}`,
                                color: colors.greenAccent[300],
                                "&:hover": {
                                    backgroundColor: `${colors.greenAccent[800]} !important`,
                                    cursor: "pointer",
                                },
                            }}
                        >
                            Start Building
                            <NorthEastIcon />
                        </Button>
                    </div>
                </div>

                <div className="min-h-[30px]">
                    {/* Using this for spacing between sections */}
                </div>


                <div className="w-2/3 border-t border-gray-300 my-4"></div>

                <div className="flex flex-row w-3/4 p-1">
                    <div className="container flex flex-col h-full w-2/3 justify-between">
                        
                        <div className="flex flex-col mt-auto text-xs md:text-md">
                            <span className="font-light"> Gidra AI</span>
                        </div>
                    </div>
                    <div className="container flex w-1/3 text-xs md:text-md justify-center space-x-4">
                        <div>
                            <h2 className="font-bold mb-2">Resources</h2>
                            <ul>
                                <li><a href="https://discord.gg/CrZuvzW7" style={{color: colors.blueAccent[500]}}>Discord</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="font-bold mb-2">Company</h2>
                            <ul>
                                <li><a href="#" style={{color: colors.blueAccent[500]}}>Careers</a></li>
                                <li><a href="#" style={{color: colors.blueAccent[500]}}>About</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="font-bold mb-2">Social</h2>
                            <ul>
                                <li><a href="https://github.com/gidra-ai/gidra_nodes" style={{color: colors.blueAccent[500]}}>GitHub</a></li>
                                <li><a href="https://www.linkedin.com/company/gidra/" style={{color: colors.blueAccent[500]}}>LinkedIn</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default HomePage;
