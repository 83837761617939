import React, { useState, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useTheme, Box } from '@mui/material';
import app from "../auth/firebase";
import {
    fetch_with_auth,
} from "../components/Util";
import { Space } from '../types/Space';
import { UserProfile } from "../types/UserProfile";
import ChatBot from "../components/ChatBot";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const SpacePage = () => {
    const theme = useTheme();
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    const [space, setSpace] = useState<Space>(new Space({
        name: '',
        description: '',
        is_public: false,
        author_id: '',
        eligible_user_emails: [],
        eligible_teams: [],
        actions: {},
        types: {},
    }));

    const initialGridData = [
        { id: "1982775896", firstName: "ARASH", lastName: "KHARESTAN", degree: "MD", address: "4733 W SUNSET BLVD", city: "LOS ANGELES", state: "CA", zip: "90027", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, GEFFEN SCHOOL OF MEDICINE", year: "1995" },
        { id: "1750420451", firstName: "ARIAN", lastName: "TORBATI", degree: "", address: "5601 DE SOTO AVE", city: "WOODLAND HILLS", state: "CA", zip: "91367", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, SAN DIEGO SCHOOL OF MEDICINE", year: "1996" },
        { id: "1801112701", firstName: "ASAD", lastName: "QASIM", degree: "", address: "10800 MAGNOLIA AVE", city: "RIVERSIDE", state: "CA", zip: "92505", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "OTHER", year: "2010" },
        { id: "1467561555", firstName: "AUNG", lastName: "TUN", degree: "MD", address: "27350 IRIS AVE", city: "MORENO VALLEY", state: "CA", zip: "92555", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "OTHER", year: "1999" },
        { id: "1730256751", firstName: "BRIAN", lastName: "KOROTZER", degree: "MD", address: "9333 ROSECRANS AVE", city: "BELLFLOWER", state: "CA", zip: "90706", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, IRVINE, CALIFORNIA COLLEGE OF MEDICINE", year: "1991" },
        { id: "1336210368", firstName: "CHIARA", lastName: "CONRADO", degree: "", address: "13652 CANTARA ST", city: "PANORAMA CITY", state: "CA", zip: "91402", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "OTHER", year: "1989" },
        { id: "1306913223", firstName: "CRAIG", lastName: "ARAKAKI", degree: "", address: "9961 SIERRA AVE", city: "FONTANA", state: "CA", zip: "92335", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, IRVINE, CALIFORNIA COLLEGE OF MEDICINE", year: "1986" },
        { id: "1124472147", firstName: "DANIEL", lastName: "TABARI", degree: "", address: "5601 DE SOTO AVE", city: "WOODLAND HILLS", state: "CA", zip: "91367", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "VIRGINIA COMMONWEALTH UNIVERSITY, SCHOOL OF MEDICINE", year: "2016" },
        { id: "1023435906", firstName: "DAVID", lastName: "DAI", degree: "", address: "6041 CADILLAC AVE", city: "LOS ANGELES", state: "CA", zip: "90034", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, SAN DIEGO SCHOOL OF MEDICINE", year: "2014" },
        { id: "1922425297", firstName: "DAVID", lastName: "KWON", degree: "", address: "9333 ROSECRANS AVE", city: "BELLFLOWER", state: "CA", zip: "90706", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "OTHER", year: "2014" },
        { id: "1013109818", firstName: "ERIC", lastName: "SANDOVAL", degree: "", address: "25825 VERMONT AVE", city: "HARBOR CITY", state: "CA", zip: "90710", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, IRVINE, CALIFORNIA COLLEGE OF MEDICINE", year: "2007" },
        { id: "1598960254", firstName: "FREDDIE", lastName: "IBRAHIM", degree: "", address: "1255 W ARROW HWY", city: "SAN DIMAS", state: "CA", zip: "91773", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "OTHER", year: "2003" },
        { id: "1184722613", firstName: "GEORGE", lastName: "YUEN", degree: "MD", address: "5475 E LA PALMA AVE", city: "ANAHEIM", state: "CA", zip: "92807", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "JS WEILL MEDICAL COLLEGE, CORNELL UNIVERSITY", year: "1997" },
        { id: "1972662500", firstName: "IWONA", lastName: "TRYBUS", degree: "", address: "4647 ZION AVE", city: "SAN DIEGO", state: "CA", zip: "92120", organization: "SOUTHERN CALIFORNIA PERMANENTE MEDICAL GROUP", license: "6002729175", specialty: "8516", school: "UNIVERSITY OF CALIFORNIA, IRVINE, CALIFORNIA COLLEGE OF MEDICINE", year: "1992" }
    ];
    
    const columnDefs = [
        { headerName: "ID", field: "id" },
        { headerName: "First Name", field: "firstName" },
        { headerName: "Last Name", field: "lastName" },
        { headerName: "Degree", field: "degree" },
        { headerName: "Address", field: "address" },
        { headerName: "City", field: "city" },
        { headerName: "State", field: "state" },
        { headerName: "ZIP", field: "zip" },
        { headerName: "Organization", field: "organization" },
        { headerName: "License", field: "license" },
        { headerName: "Specialty", field: "specialty" },
        { headerName: "School", field: "school" },
        { headerName: "Year", field: "year" }
    ];

    const [gridData, setGridData] = useState<any[]>(initialGridData);

    const fetchSpace = async (space_id: string, id_token: string) => {
        const data = await fetch_with_auth(
            `space?space_id=${space_id}`,
            id_token,
            "GET"
        );
        return data;
    };

    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                const searchParams = new URLSearchParams(window.location.search);
                const newSpace = await fetchSpace(searchParams.get("space_id") || "", up.id_token || "");
                setSpace(newSpace);
                // Assuming newSpace contains the grid data
                setGridData(initialGridData);
            }
        });

        return () => unsubscribe();
    }, []);

    

    return (
        <Box display="flex" height="100vh">
            <Box flex={7} className="ag-theme-alpine" style={{ height: '100%', width: '70%' }}>
                <AgGridReact
                    rowData={gridData}
                    columnDefs={columnDefs}
                    pagination={true}
                    paginationPageSize={20}
                />
            </Box>
            <Box flex={3} style={{ height: '100%', width: '30%', padding: '10px' }}>
                <ChatBot
                    space={space}
                    setSpace={setSpace}
                    userProfile={userProfile}
                    setUserProfile={setUserProfile}
                />
            </Box>
        </Box>
    );
};

export default SpacePage;
