import { XYPosition } from "reactflow";
import { Dispatch, SetStateAction, useCallback } from "react";

import {
    apiUrl,
} from "../components/Util";

type PipelineStepType = {
    id: string;
    operator: string;
    parameters: Record<string, any>;
    inputs: Record<string, [string, string]>;
    position: XYPosition;
    batch: boolean;
};

export class PlConfig {
    pl_config_hash: string;
    creator_user_id: string;
    created_ts: Date | null;
    token_limit: number | null;
    budget_limit_usd: number | null;
    memory_objects: string[];
    temperature: number;
    preferred_model: string;
    pipeline: PipelineStepType[];
    name: string | null;

    constructor(plConfig: Partial<PlConfig>) {
        this.pl_config_hash = plConfig.pl_config_hash!;
        this.creator_user_id = plConfig.creator_user_id!;
        this.created_ts = plConfig.created_ts || null;
        this.token_limit = plConfig.token_limit || null;
        this.budget_limit_usd = plConfig.budget_limit_usd || null;
        this.memory_objects = plConfig.memory_objects || [];
        this.temperature = plConfig.temperature!;
        this.preferred_model = plConfig.preferred_model!;
        
        const pl : PipelineStepType[] | string = plConfig.pipeline || [];
        
        if (!pl) {
            this.pipeline = [];
        } else if (typeof pl === 'string') {
            this.pipeline = JSON.parse(pl);
        } else {
            this.pipeline = pl;
        }

        this.name = plConfig.name || null;
    }

    update(setState: React.Dispatch<React.SetStateAction<PlConfig>>, someValues: Partial<PlConfig>) {
        const newInstance = new PlConfig({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }
    
    async sha256Hash(message: string) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);                    

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string                  
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }
    
    async calculate_hash() {
        const { pl_config_hash, created_ts, creator_user_id, ...restOfData } = this;
        const json_string = JSON.stringify(restOfData);
        this.pl_config_hash = await this.sha256Hash(json_string);
        return this.pl_config_hash;
    }
    
    async save_to_spanner(): Promise<string> {
        await this.calculate_hash();
    
        const response = await fetch(`${apiUrl}/pl_config`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        this.pl_config_hash = data.pl_config_hash;

        return this.pl_config_hash;
    }
}


export type {PipelineStepType};
