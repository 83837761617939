import { Dispatch, SetStateAction } from "react";
import {
    apiUrl,
    fetch_with_auth
} from "../components/Util";
import { PlConfig } from "./PlConfig";

interface ActionsDictionary {
    [key: string]: string;
}

interface TypesDictionary {
    [key: string]: string;
}

export class Space {
   
    space_id: string;
    name: string | null;
    description: string | null;
    is_public: boolean | null;
    star_count: number;
    author_id: string;
    author_name: string | null;
    created_ts: Date | null;
    eligible_user_emails: string[];
    eligible_teams: string[];
    actions: ActionsDictionary;
    types: TypesDictionary;
    

    constructor(space: Partial<Space>) {
        this.space_id = space.space_id!;
        this.name = space.name || null;
        this.description = space.description || null;
        this.is_public = space.is_public || null;
        this.star_count = space.star_count || 0;
        this.author_id = space.author_id!;
        this.author_name = space.author_name || null;
        this.created_ts = space.created_ts || null;
        this.eligible_user_emails = space.eligible_user_emails || [];
        this.eligible_teams = space.eligible_teams || [];
        
        if (typeof space.actions === "string") {
            try {
                this.actions = JSON.parse(space.actions);
            } catch (error) {
                console.error("Parsing space.actions failed", error);
                this.actions = {};
            }
        } else {
            this.actions = space.actions || {};
        }

        if (typeof space.types === "string") {
            try {
                this.types = JSON.parse(space.types);
            } catch (error) {
                console.error("Parsing space.types failed", error);
                this.types = {};
            }
        } else {
            this.types = space.types || {};
        }
    }

    update(setState: Dispatch<SetStateAction<Space>>, someValues: Partial<Space>) {
        const newInstance = new Space({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }
    
    static async loadSpace(space_id: string): Promise<Space> {
        const response = await fetch(`${apiUrl}/space?space_id=${space_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP response code is ${response.status}, expected 200`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('Invalid response data');
        }

        const space = new Space(data);

        return space;
    }

    static async delete_from_spanner(space_id : string, id_token : string) {
        const response = await fetch_with_auth(`space?space_id=${space_id}`, id_token, 'DELETE');
        return true;
    }

    async save_to_spanner(id_token : string) {
        return await fetch_with_auth('saved_item', id_token, 'POST', this)
    }
};
