import React from 'react';
import { ansiToReact } from "./Util";

interface ActionProps {
  content: string;
}

const Action: React.FC<ActionProps> = ({ content }) => {
  return (
    <div className="flex flex-col p-2 rounded-md mb-2">
      {ansiToReact(content)}
    </div>
  );
};

export default Action;
