import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {LoginButton} from "../components/LoginBtn";
import BlinkingCursor from "../components/BlinkingCursor";

interface TopbarProps {
    isSmallScreen: boolean;
    isLandingRoute: boolean;
    isCollapsed: boolean;
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
    isHidden: boolean;
    setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const Topbar = ({ isSmallScreen, isLandingRoute, setIsCollapsed, isHidden, setIsHidden }: TopbarProps) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);

    const handleMenuClick = () => {
        setIsHidden(!isHidden);
        setIsCollapsed(false);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            p={2}
        >
            <Box flexGrow={1}>
                {isSmallScreen && isHidden && !isLandingRoute && (
                    <IconButton onClick={handleMenuClick}>
                        <MenuOutlinedIcon />
                    </IconButton>
                )}
                {isLandingRoute && isSmallScreen && (
                    <div className="h-full align-middle pl-4">
                        <BlinkingCursor
                            text={"Gidra IA"}
                            speed={100}
                            text_variant="h3"
                            isBlinking={true}
                            isTyping={false}
                        />
                    </div>
                )}
                {isLandingRoute && !isSmallScreen && (
                    <div className="h-full align-middle pl-4">
                        <BlinkingCursor
                            text={"Gidra IA"}
                            speed={100}
                            text_variant="h2"
                            isBlinking={true}
                            isTyping={false}
                        />
                    </div>
                )}
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                {/* <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <PersonOutlinedIcon />
                </IconButton> */}
                <LoginButton />
            </Box>
        </Box>
    );
};

export default Topbar;
