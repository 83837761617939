import React, {
    useState,
    useEffect,
    useRef,
    SetStateAction,
    Dispatch,
} from "react";
import { Box, Typography, TextField, Button, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { tokens } from "../theme";
import { PlRun } from "../types/PlRun";
import { PlConfig } from "../types/PlConfig";
import { UserProfile } from "../types/UserProfile";
import { apiUrl } from "./Util";
import toast from "react-hot-toast";
import Loader from "./Loader";
import { Space } from "../types/Space";

interface Message {
    user: "bot" | "user";
    text: string;
}
interface ChatBotProps {
    space: Space;
    setSpace: Dispatch<SetStateAction<Space>>;

    userProfile: UserProfile;
    setUserProfile: Dispatch<SetStateAction<UserProfile>>;
}

const ChatBot: React.FC<ChatBotProps> = (props) => {
    const [input, setInput] = useState<string>("");
    const [messages, setMessages] = useState<Message[]>([]);
    const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendMessage();
        setMessages((prev) => [...prev, { user: "user", text: input }]);
        setInput("");
    };

    const sendMessage = async () => {
        // Leaving it noop for now before proper space agency logic is implemented.
        // Clone the existing plConfig to avoid directly modifying the props
        //const newPlConfigJson = JSON.parse(JSON.stringify(props.plConfig));
        // If pipeline array is not empty and the first item has the "query" field
        //if (
        //    newPlConfigJson.pipeline.length > 0 &&
        //    "query" in newPlConfigJson.pipeline[0].parameters
        //) {
        //    newPlConfigJson.pipeline[0].parameters.query = input;
        //}

        //const newPlRun = await PlRun.startRun(
        //    new PlConfig(newPlConfigJson), 
        //    props.userProfile, 
        //    props.setUserProfile,
        //    props.userProfile.id_token || ""
        //);
        //if (newPlRun) {
        //    props.setPlRun(new PlRun(newPlRun));
        //    props.setIsRunning(true);
        //} else {
        //    toast.error("Failed to send message!")
        //    console.log("newPlRun is null");
        //}
    };

    return (
        <Box
            sx={{
                height: "60vh",
                display: "flex",
                flexDirection: "column",
                bgcolor: colors.primary[400],
                borderRadius: "5px",
                border: "1px solid",
                borderColor: colors.primary[300],
            }}
            className="m-4 p-4 min-w-full"
        >
            <Box
                sx={{
                    flexGrow: 1,
                    overflowY: "auto",
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {messages.map((message, i) => (
                    <Box
                        key={i}
                        sx={{
                            alignSelf:
                                message.user === "bot"
                                    ? "flex-start"
                                    : "flex-end",
                            m: 1,
                            p: 1,
                            bgcolor:
                                message.user === "bot"
                                    ? colors.blueAccent[900]
                                    : colors.greenAccent[900],
                            borderRadius: "5px",
                        }}
                    >
                        <Typography variant="body1">{message.text}</Typography>
                    </Box>
                ))}
                <div ref={endOfMessagesRef} />
            </Box>
            <form className="flex flex-row" onSubmit={handleSubmit}>
                <TextField
                    className="flex "
                    value={input}
                    onChange={handleInputChange}
                    placeholder="Send a message..."
                    sx={{ flexGrow: 1, mr: 2 }}
                    disabled={false}
                    inputRef={inputRef}
                />
                <Button type="submit" disabled={false}>
                    <SendIcon />
                </Button>
            </form>
        </Box>
    );
};

export default ChatBot;
