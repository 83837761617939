import { Dispatch, SetStateAction } from "react";
import { User } from "firebase/auth";
import Cookies from "js-cookie";

import {
    apiUrl,
} from "../components/Util";

function _getUserId(user: User | null): string {
    return user?.uid || Cookies.get("__UID") || '';
}

export class UserProfile {
    user_id: string;
    openai_token: string | null;
    fuel_source_id: string | null;
    openai_fuel_preference: string | null;
    color_scheme: string | null;
    id_token: string | null;
    user_email: string | null;
    display_name: string | null;

    constructor(userProfile: Partial<UserProfile>) {
        this.user_id = userProfile.user_id!;
        this.openai_token = userProfile.openai_token || null;
        this.fuel_source_id = userProfile.fuel_source_id || null;
        this.openai_fuel_preference = userProfile.openai_fuel_preference || null;
        this.color_scheme = userProfile.color_scheme || null;
        this.id_token = userProfile.id_token || null;
        this.user_email = userProfile.user_email || null;
        this.display_name = userProfile.display_name || null;
    }

    update(setState: React.Dispatch<React.SetStateAction<UserProfile>>, someValues: Partial<UserProfile>) {
        const newInstance = new UserProfile({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }
    
    useFuelSource(): boolean {
        return this.openai_fuel_preference === 'fuel_source';
    }
    
    useOpenaiToken(): boolean {
        return this.openai_fuel_preference === 'token';
    }
    
    async init(setState: Dispatch<SetStateAction<UserProfile>>, user : User | null): Promise<Partial<UserProfile> | null> {
        const quickUpdate: Partial<UserProfile> = {
            user_id: _getUserId(user),
            openai_token: Cookies.get("__OAI_TOK") || null,
            fuel_source_id: Cookies.get("__FSID") || null,
            openai_fuel_preference: Cookies.get("openai_fuel_preference") || "token",
            color_scheme: Cookies.get("color_scheme") || "light",
            id_token: user ? await user.getIdToken() : null,
            user_email: user ? user.email : null,
            display_name: user ? user.displayName : null,
        };

        if (quickUpdate.fuel_source_id && !quickUpdate.openai_token) {
            quickUpdate.openai_fuel_preference = 'fuel_source';
        }
        
        this.update(setState, quickUpdate);
        
        // Hacky way to tell that user is authenticated.
        if (quickUpdate.user_id && quickUpdate.user_id.length < 36) {
            try {
                const response = await fetch(`${apiUrl}/user_profile?user_id=${quickUpdate.user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                
                const fetchedUserProfile: Partial<UserProfile> = await response.json();
                // (1) Browser cookie is source of truth for OpenAI token on frontned so we need
                // to make sure that openai_token property of userProfile is not overriden
                // by None value that it would have on backend.
                // (2) id_token token is not stored on backend too thus it should not be 
                // overriden in quickUpdate UserProfile instance.
                const fieldsToDelete: (keyof UserProfile)[] = ["openai_token", "id_token", "user_email"];
                fieldsToDelete.forEach(field => {
                    if (field in fetchedUserProfile) {
                        delete fetchedUserProfile[field];
                    }
                });

                const userProfileComplete = {
                    ...quickUpdate,
                    ...fetchedUserProfile,
                };

                this.update(setState, userProfileComplete);
                return userProfileComplete;
            } catch (error) {
                console.error('An error occurred while fetching the user profile:', error);
            }
        }
        return quickUpdate;
    }
    
    async save_to_spanner() {
        const response = await fetch(`${apiUrl}/user_profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    }
}

