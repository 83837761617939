import React, { useState, useEffect } from "react";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { TypographyProps } from "@mui/material/Typography";

type TextVariant = TypographyProps["variant"];

interface BlinkingCursorProps {
    text: string;
    speed: number;
    text_variant: TextVariant;
    isBlinking?: boolean;
    isTyping?: boolean;
}

const BlinkingCursor: React.FC<BlinkingCursorProps> = ({
    text,
    speed,
    text_variant,
    isBlinking = false,
    isTyping = true,
}) => {
    const [currentText, setCurrentText] = useState("");
    const [blink, setBlink] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        let currentIndex = 0;
        const typeTitle = () => {
            if (currentIndex < text.length) {
                setCurrentText(text.slice(0, currentIndex + 1));
                currentIndex += 1;
                setTimeout(typeTitle, speed);
            } else {
                const blinkInterval = setInterval(() => {
                    setBlink((prevBlink) => !prevBlink);
                }, 810);
                return () => clearInterval(blinkInterval);
            }
        };
        if (isTyping) {
            typeTitle();
        } else {
            setCurrentText(text);
            const blinkInterval = setInterval(() => {
                setBlink((prevBlink) => !prevBlink);
            }, 810);
            return () => clearInterval(blinkInterval);
        }
    }, [text, isTyping]);

    return (
        <div className="flex min-h-full items-center">
            <Typography
                variant={text_variant}
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{
                    m: "0 0 5px 0",
                }}
            >
                {currentText}
                {isBlinking && (
                    <span
                        style={{
                            opacity: blink ? 1 : 0,
                            transition: "opacity 0.1s",
                        }}
                    >
                        _
                    </span>
                )}
            </Typography>
        </div>
    );
};

export default BlinkingCursor;
