import React from "react";

interface GidraTitleProps {
    title?: string;
    subtitle?: string;
}

const GidraTitle: React.FC<GidraTitleProps> = ({
    title = "Gidra AI",
    subtitle = "Platform for building AI apps",
}) => {

    return (
        <>
            <h2>{title}</h2>
            <h6>{subtitle}</h6>
        </>
    );
};

export default React.memo(GidraTitle);

