import { Dispatch, SetStateAction } from "react";

import { fetch_with_auth } from "../components/Util"

export class Team {
    name: string;
    domains: string[];
    members: string[];
    admins: string[];

    constructor(team: Partial<Team>) {
        this.name = team.name!;
        this.domains = team.domains || [];
        this.members = team.members || [];
        this.admins = team.admins || [];
    }

    update(setState: Dispatch<SetStateAction<Team>>, someValues: Partial<Team>) {
        const newInstance = new Team({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }

    static async create_new(idToken: string, name: string) {
        const data = await fetch_with_auth(`team`, idToken, 'POST', {
            action: 'create',
            name: name,
        });

        return new Team(data.team);
    }

    async add_user(id_token: string, user_email: string, admin: boolean = false) {
        const data = await fetch_with_auth(`team`, id_token, 'POST', {
            action: admin ? 'add_admin' : 'add_user',
            user_email: user_email,
            name: this.name,
        });

        return new Team(data.team);
    }

    async remove_user(id_token: string, user_email: string) {
        const data = await fetch_with_auth(`team`, id_token, 'POST', {
            action: 'remove_user',
            user_email: user_email,
            name: this.name,
        });

        return new Team(data.team);
    }

    async remove_admin(id_token: string, user_email: string) {
        const data = await fetch_with_auth(`team`, id_token, 'POST', {
            action: 'remove_admin',
            user_email: user_email,
            name: this.name,
        });

        return new Team(data.team);
    }
    
    static async get_teams_for_user(id_token: string): Promise<Team[]> {
        const data = await fetch_with_auth(`team`, id_token, 'GET');
        
        // data should be an array of team objects, so we map each object to a Team instance
        return data.map((team: Partial<Team>) => new Team(team));
    }
}

