import { useEffect, useState } from "react";
import {
    signOut,
    getAuth,
    onAuthStateChanged,
    User,
} from "firebase/auth";
import app from "../auth/firebase";
import { googleSignIn } from "../auth/login";

export function LoginButton() {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const auth = getAuth(app);

    const handleSignIn = async () => {
        if (!auth.currentUser) {
            googleSignIn();
        }
    };

    useEffect(() => {
        const refresh = onAuthStateChanged(auth, (user) => { // this is a listener that triggers every time the user's authentication state changes
            setCurrentUser(user);
        });

        return () => {
            refresh();
        };
    }, [auth]);

    if (currentUser) {
        return (
            <div className="flex flex-col space-y-1 h-full !border-black">
                <div className="flex items-center justify-center">
                    <p>{currentUser.displayName}</p>
                </div>
                <div className="flex items-center justify-center">
                    <img
                        src={currentUser.photoURL || ""}
                        referrerPolicy="no-referrer"
                        style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                        }}
                    />
                </div>
                <button onClick={() => signOut(auth)}>Sign out</button>
            </div>
        );
    }

    return (
        <>
            <button onClick={handleSignIn}>Sign in</button>
        </>
    );
}
