import React from "react";
import {
    Card,
    CardContent,
    Typography,
    useTheme,
    Box,
    Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps, Theme } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import { tokens } from "../theme";
import { apiUrl } from "./Util";
import toast from "react-hot-toast";
import { SavedItem } from '../types/SavedItem';
import { UserProfile } from '../types/UserProfile';
import {
    fetch_with_auth,
} from "./Util";


interface AgentCardProps {
    item: SavedItem;
    isMine?: boolean;
    userProfile: UserProfile;
}

const AgentCard: React.FC<AgentCardProps> = ({ item, isMine = false, userProfile }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const cardStyle: SxProps<Theme> = {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        transition: "transform 0.2s, box-shadow 0.2s, border 0.2s",
        border: "1px solid",
        borderColor: colors.primary[300],
        boxShadow: `1px 1px ${colors.primary[300]}`,
        "&:hover": {
            transform: "scale(1.05)",
            cursor: "pointer",
            borderColor: colors.primary[100],
        },
    };

    const handleClick = () => {
        var endpoint = 'pipeline';
        var urlParamName = 'saved_pipeline_id';
        if (item.saved_item_type == 'agent') {
            endpoint = 'agent';
            urlParamName = 'agent_id';
        }
        window.location.href = `${window.location.origin}/${endpoint}?${urlParamName}=${item.saved_item_id}`;
    };

    const handleDelete = async (event: React.MouseEvent) => {
        event.stopPropagation(); // This will stop the event from bubbling up and prevent the parent handler (handleClick) from being notified
        toast.loading("Deleting your published pipeline...");
        try {
            await SavedItem.delete_from_spanner(item.saved_item_id, userProfile.id_token || '');
            toast.dismiss();
            toast.success('Deleted');
        } catch (error: any) {
            console.error(error);
            toast.dismiss();
            toast.error(`Deletion failed: ${error.message}`);
        }

    };

    return (
        <Card sx={cardStyle} onClick={handleClick}>
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <Stack spacing={2}>
                    <div className="flex justify-between items-center">
                        <Typography variant="h5" align="left" gutterBottom>
                            {item.name}
                        </Typography>
                        {isMine && (
                            <div>
                                <IconButton
                                    onClick={handleDelete}
                                    sx={{
                                        color: colors.redAccent[500],
                                        "&:hover": {
                                            backgroundColor:
                                                colors.primary[900],
                                            color: colors.redAccent[300],
                                        },
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    <div>
                        <Typography variant="body1" align="left">
                            {item.description}
                        </Typography>
                    </div>
                </Stack>
                <Box mt="auto">
                    <Box className="flex flex-row space-x-4">
                        <Typography
                            variant="body2"
                            align="left"
                            color="text.secondary"
                        >
                            {`Author: ${item.author_name}`}
                        </Typography>
                        <Box className="flex flex-row justify-center items-center space-x-2">
                            <StarIcon color="warning" />
                            <Typography
                                variant="body2"
                                align="left"
                                color="text.secondary"
                            >
                                {`Stars: ${item.star_count}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default AgentCard;
