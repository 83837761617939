// MyAgentsGrid.tsx
import React from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import AgentCard from "./AgentCard";
import { tokens } from "../theme";
import CreateAgentPopup from "./CreateOptionsPopup";
import { SavedItem } from "../types/SavedItem";
import { UserProfile } from "../types/UserProfile";


interface MyAgentsGridProps {
    items: SavedItem[];
    handleCreateAgent: () => void;
    userProfile: UserProfile;
}

const MyAgentsGrid: React.FC<MyAgentsGridProps> = ({
    items,
    handleCreateAgent,
    userProfile,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <>
            {items.length === 0 ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    className="p-5"
                >
                    <Typography variant="body1" gutterBottom>
                        None yet!
                    </Typography>
                    <div className="flex flex-row min-w-full items-center justify-center space-x-4">
                        <CreateAgentPopup />
                    </div>
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {items.map((item, index) => (
                        <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                            <AgentCard item={item} isMine={true} userProfile={userProfile}/>
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

export default MyAgentsGrid;
