import { Dispatch, SetStateAction } from "react";
import {
    apiUrl,
    fetch_with_auth
} from "../components/Util";
import { PlConfig } from "./PlConfig";

interface ActionsDictionary {
    [key: string]: string;
}

export class SavedItem {
    saved_item_id: string;
    saved_item_type: string;
    saved_item_ref: string;
    name: string | null;
    description: string | null;
    is_public: boolean | null;
    star_count: number;
    author_id: string;
    author_name: string | null;
    created_ts: Date | null;
    eligible_user_emails: string[];
    eligible_teams: string[];
    actions: ActionsDictionary;
    

    constructor(savedItem: Partial<SavedItem>) {
        this.saved_item_id = savedItem.saved_item_id!;
        this.saved_item_type = savedItem.saved_item_type!;
        this.saved_item_ref = savedItem.saved_item_ref!;
        this.name = savedItem.name || null;
        this.description = savedItem.description || null;
        this.is_public = savedItem.is_public || null;
        this.star_count = savedItem.star_count || 0;
        this.author_id = savedItem.author_id!;
        this.author_name = savedItem.author_name || null;
        this.created_ts = savedItem.created_ts || null;
        this.eligible_user_emails = savedItem.eligible_user_emails || [];
        this.eligible_teams = savedItem.eligible_teams || [];
        
        if (typeof savedItem.actions === "string") {
            try {
                this.actions = JSON.parse(savedItem.actions);
            } catch (error) {
                console.error("Parsing savedItem.actions failed", error);
                this.actions = {};
            }
        } else {
            this.actions = savedItem.actions || {};
        }
    }

    update(setState: Dispatch<SetStateAction<SavedItem>>, someValues: Partial<SavedItem>) {
        const newInstance = new SavedItem({
            ...this,
            ...someValues,
        });
        setState(newInstance);
    }
    
    static async loadSavedItemAndPlConfig(saved_item_id: string): Promise<{ savedItem: SavedItem, plConfig: PlConfig }> {
        const response = await fetch(`${apiUrl}/saved_item_plconfig?saved_item_id=${saved_item_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP response code is ${response.status}, expected 200`);
        }

        const data = await response.json();

        if (!data.saved_item) {
            throw new Error('Response data is missing saved_item');
        }

        const savedItem = new SavedItem(data.saved_item);
        const plConfig = new PlConfig(data.pl_config);

        return { savedItem, plConfig };
    }

    static async delete_from_spanner(saved_item_id : string, id_token : string) {
        const response = await fetch_with_auth(`saved_item?saved_item_id=${saved_item_id}`, id_token, 'DELETE');
        return true;
    }

    async save_to_spanner(id_token : string) {
        return await fetch_with_auth('saved_item', id_token, 'POST', this)
    }
};
