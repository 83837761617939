import React, {useEffect, useState} from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import GidraTitle from "../components/GidraTitle";
import PublishedAgentsGrid from "../components/PublishedAgentsGrid";
import { tokens } from "../theme";
import MyAgentsGrid from "../components/MyAgentsGrid";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app from "../auth/firebase";
import { apiUrl, fetch_with_auth } from "../components/Util";
import CreateAgentPopup from "../components/CreateOptionsPopup";
import GithubLink from '../components/GithubLink';
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { UserProfile } from '../types/UserProfile';


const HubPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const myAgents : any[] = [];
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));
    
    const navigate = useNavigate();
    const location = useLocation();

    const [publishedItems, setPublishedItems] = useState([]);
    const [yourItems, setYourItems] = useState([]);
    const [isPublishedItemsLoading, setIsPublishedItemsLoading] = useState(true);
    const [isYourItemsLoading, setIsYourItemsLoading] = useState(true);

    const fetchPublicItems = async () => {
        setIsPublishedItemsLoading(true);
        try {
            const data = await fetch_with_auth(
                'saved_item?visibility=public', 
                '',  // id_token parameter is not needed for loading public saved items.
                'GET'
            )

            setPublishedItems(data);
            setIsPublishedItemsLoading(false); 
        } catch (error) {
            console.error("Error:", error);
            setIsPublishedItemsLoading(false); 
        }
    };
    
    const fetchPrivateItems = async (id_token : string) => {
        setIsYourItemsLoading(true);
        try {
            const data = await fetch_with_auth(
                'saved_item?visibility=private', 
                id_token, 
                'GET'
            )

            setYourItems(data);
            setIsYourItemsLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setIsYourItemsLoading(false);
        }
    };
    
    useEffect(() => {
        const auth = getAuth(app);
    
        const refresh = onAuthStateChanged(auth, async (currentUser) => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                fetchPrivateItems(up.id_token || '');
            }
        });

        return () => {
            refresh(); // Clean up subscription on unmount
        };
    }, []);
    
    useEffect(() => {
        fetchPublicItems();

        const searchParams = new URLSearchParams(window.location.search);
        const newFuelSourceId = searchParams.get("fuel_source_id");
        //If there is a new fuel source id, reset the cookie FSID
        if (newFuelSourceId) {
            Cookies.set("__FSID", newFuelSourceId, { sameSite: "strict" });
            userProfile.update(setUserProfile, {fuel_source_id: newFuelSourceId});
        }
    }, []);



    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const runId = searchParams.get("run_id");
        if (runId) {
            navigate(`/pipeline?run_id=${runId}`);
        }
    }, [navigate, location]);

    const handleCreateAutoGPTAgent = () => {
        window.location.href = `${window.location.origin}/pipeline`;
    };
    
    return (
        <div className="flex flex-col min-h-[90vh] min-w-full space-y-6">
            <div className="flex flex-col p-4 justify-center items-center min-w-screen min-h-[150px] space-y-2">
                <GidraTitle />
                <GithubLink />
            </div>
            <Box 
                className="flex flex-col max-w-5/6 min-h-full items-center m-4 p-4 rounded-md"
                sx={{
                    backgroundColor: colors.primary[400],
                    border: `1px solid`,
                    borderColor: colors.primary[300],
                }}
            >
                {isYourItemsLoading ? <Loader size={25} /> : (
                    <>
                        <div className="flex justify-between items-center w-full mb-5">
                            <Typography variant="h5" gutterBottom>
                                Your Pipelines
                            </Typography>
                            {yourItems.length > 0 && <CreateAgentPopup />}
                        </div>
                        <div className="min-w-full">
                            <MyAgentsGrid items={yourItems} handleCreateAgent={handleCreateAutoGPTAgent} userProfile={userProfile}/>
                        </div>
                    </>
                )}
            </Box>

            <Box 
            className="flex flex-col max-w-5/6 min-h-full items-center m-4 p-4 rounded-md"
            sx={{
                backgroundColor: colors.primary[400],
                border: `1px solid`,
                borderColor: colors.primary[300],
            
            }}
            >
               {isPublishedItemsLoading ? <Loader size={25} /> : (
                    <>
                        <div className="min-w-full mb-5">
                            <Typography variant="h5" gutterBottom>
                                Published Pipelines
                            </Typography>
                        </div>
                        <div className="min-w-full">
                            <PublishedAgentsGrid items={publishedItems} userProfile={userProfile}/>
                        </div>
                    </>
                )}
            </Box>
        </div>
    );
};

export default HubPage;
