import React from "react";
import { Card, useTheme, IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import { tokens } from "../theme";
import { apiUrl } from "./Util";
import { getAuth } from "firebase/auth";
import app from "../auth/firebase";
import { UserProfile } from "../types/UserProfile"


interface FileItemProps {
    userProfile: UserProfile;
    fileName: string;
    onDragStart: (e: React.DragEvent<HTMLDivElement>, fileName: string) => void;
}

const FileItem: React.FC<FileItemProps> = ({ userProfile, fileName, onDragStart }) => {
    const auth = getAuth(app);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleDownload = async (e: React.MouseEvent) => {
        console.log("Downloading file");
        e.stopPropagation(); // Add this line to prevent conflicts with onDragStart event
        try {
            // Get the run_id value from the URL
            const searchParams = new URLSearchParams(window.location.search);
            const run_id = searchParams.get("run_id");
    
            const formData = new FormData();
    
            if(run_id !== null){
                formData.append("run_id", run_id);
            }
            formData.append("file_name", fileName);
            formData.append("user_id", userProfile.user_id);
    
            const response = await fetch(`${apiUrl}/download_file`, {
                method: "POST",
                headers: {
                },
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error("File download failed");
            }
    
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error: any) {
            console.error("Error downloading file:", error.message);
        }
    };
    

    return (
        <Card
            draggable
            onDragStart={(e) => onDragStart(e, fileName)}
            className="border border-gray-300 rounded cursor-move shadow-md m-2"
            sx={{
                position: "relative", // Add this line
                backgroundColor: colors.primary[400],
                borderColor: colors.grey[500],
                padding: "5px",
                fontSize: {
                    xs: "0.65rem",
                    sm: "0.75rem",
                    md: "0.75rem",
                    lg: "0.75rem",
                    xl: "0.75rem",
                },
                margin: "0.2rem",
            }}
        >
            <span
                style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingRight: "2rem", // Add space for the download button
                }}
            >
                {fileName}
            </span>
            <IconButton
                edge="end"
                color="inherit"
                onClick={(e) => handleDownload(e)} // Pass the event to the handleDownload function
                aria-label="download"
                size="small"
                sx={{
                    position: "absolute", // Add this line
                    right: "5px", // Add this line
                    top: "50%", // Add this line
                    transform: "translateY(-50%)", // Add this line
                }}
            >
                <GetAppIcon />
            </IconButton>
        </Card>
    );
};

export default FileItem;
