// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDaLt6G8yDGtE9xMtjTWslY6c8iRy7neU",
  authDomain: "gidra-project.firebaseapp.com",
  projectId: "gidra-project",
  storageBucket: "gidra-project.appspot.com",
  messagingSenderId: "267338093806",
  appId: "1:267338093806:web:a314ed79090629ebcf4fff"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
