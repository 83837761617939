import { Box, useTheme } from '@mui/material';
import React from 'react'
import FileItem from './FileItem';
import { tokens } from '../theme';
import { UserProfile } from "../types/UserProfile"

interface FileListProps {
    userProfile: UserProfile;
    files: string[];
    onDragStart: (e: React.DragEvent<HTMLDivElement>, fileName: string) => void;
}

const FileList: React.FC<FileListProps> = ({ userProfile, files, onDragStart }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Box className="flex flex-wrap min-w-full overflow-y-auto max-h-80 min-h-[10px] border p-2 rounded"
        sx={{
            padding: "5px",
            borderRadius: "8px",
            borderColor: colors.grey[500],
            backgroundColor: colors.primary[400],
        }}>
            {(!files || files.length === 0) ? (
                <div className="text-center w-full"> No files yet</div>
            ) : (
                files.map((file, index) => (
                    <div
                        className={`w-1/2${index === 0 ? "" : " mt-2"}`}
                        key={index}
                    >
                        <FileItem userProfile={userProfile} fileName={file} onDragStart={onDragStart} />
                    </div>
                ))
            )}
        </Box>
    );
};

export default FileList
