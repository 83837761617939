import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { 
    Button, 
    TextField, 
    Typography, 
    Container, 
    TableContainer, 
    Paper, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody,  
    IconButton,
    Box, 
    ClickAwayListener, 
    Grow, 
    Popper,
    Link as MuiLink,
    colors
} from '@mui/material';
import { Pageview as PageviewIcon } from '@mui/icons-material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import app from "../auth/firebase";

import ActionFeed from '../components/ActionFeed';
import { apiUrl, getCurrentOrigin } from "../components/Util";
import Header from '../components/Header';
import { tokens } from '../theme';
import { PlConfig } from "../types/PlConfig";
import { PlRun } from "../types/PlRun";
import { UserProfile } from "../types/UserProfile";


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 900,
  },
});

const formatDateToLocal = (date: Date | null) => {
    if (date) {
        return new Intl.DateTimeFormat('default', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        }).format(date);
    } else {
        return "N/A";
    }
};

const HistoryPage: React.FC = () => {
    const [openaiToken, setOpenaiToken] = useState('');
    const [plRuns, setPlRuns] = useState<PlRun[]>([]);

    const [openTooltip, setOpenTooltip] = useState(false);
    const [tooltipLog, setTooltipLog] = useState<string[]>([]);
    const [tooltipAnchorEl, setTooltipAnchorEl] = useState<null | HTMLElement>(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [userProfile, setUserProfile] = useState<UserProfile>(new UserProfile({ user_id: "" }));


    const fetchHistory = async ( user_id: string | undefined ) => {
        try {
            const response = await fetch(`${apiUrl}/history?user_id=${user_id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            const newPlRuns = data.map((element: any) => new PlRun(element));
            
            newPlRuns.sort((a: PlRun, b: PlRun) => {
                const aDate = a.created_ts ? new Date(a.created_ts).getTime() : 0;
                const bDate = b.created_ts ? new Date(b.created_ts).getTime() : 0;
                return bDate - aDate;
            });
            setPlRuns(newPlRuns);
        } catch (error) {
            console.error('Error fetching agent history:', error);
        }
    };
    
    useEffect(() => {
        const auth = getAuth(app);
        const unsubscribe = onAuthStateChanged(auth, async currentUser => {
            const up = await userProfile.init(setUserProfile, currentUser);
            if (up) {
                fetchHistory(up.user_id!);
            } 
        });

        return () => unsubscribe();
    }, []);

    const handleTooltipOpen = (log: string[], target: HTMLElement) => {
        console.log(`handleTooltipOpen invoked`);
    
        setTooltipLog(log);
        setTooltipAnchorEl(target);
        setOpenTooltip(true);
    };

    const handleTooltipClose = () => {
        console.log(`handleTooltipClose invoked`);
    
        setOpenTooltip(false);
    };

    //onChange={(e) => setOpenaiToken(e.target.value)}

  return (
    <Container className="min-h-screen min-w-full">
        <Header title="History" subtitle='Revisit any of your previous agents'></Header>
      <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Pl Config Hash</TableCell>
                    <TableCell>Run ID</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Finished</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Log</TableCell>
                </TableRow>
            </TableHead>
              
            <TableBody>
                {plRuns.map((run, index) => (
                <TableRow key={index} sx={{ height: 'auto' }}>
                    <TableCell>{run.pl_config_hash}</TableCell>
                    <TableCell>
                    <MuiLink
                        href={`${getCurrentOrigin()}/pipeline?run_id=${run.run_id}`}
                        target="_blank"
                        rel="noreferrer"
                        sx={{
                            textDecoration: 'underline',
                            color: colors.blueAccent[500]
                        }}
                        >
                        {run.run_id}
                    </MuiLink>
                    </TableCell>
                    <TableCell>{run.state}</TableCell>
                    <TableCell>
                        {formatDateToLocal(run.created_ts)}
                    </TableCell>
                    <TableCell>
                        {formatDateToLocal(run.finished_ts)}
                    </TableCell>
                    <TableCell>{run.user_id}</TableCell>
                    <TableCell>
                        <CustomWidthTooltip
                            max-width="1000"
                            title={
                                <Box sx={{ width: '100%' }}>
                                    <Box p={2}>
                                        <ActionFeed userProfile={userProfile} plRun={run} />
                                    </Box>
                                </Box>
                            }
                            
                            arrow
                            placement="bottom-end"
                        >
                            <IconButton>
                                <PageviewIcon />
                            </IconButton>
                        </CustomWidthTooltip>
                    </TableCell>

                </TableRow>
                ))}
            </TableBody>
              
        </Table>
      </TableContainer>
    </Container>
  );
};

export default HistoryPage;

